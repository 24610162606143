import { css, cx } from '@emotion/css'
import { IonButton, IonContent, IonModal, IonSearchbar } from '@ionic/react'
import * as React from 'react'
import 'swiper/css'
import { HideDesktopStyles } from '../../../utils/styles/ViewportUtils.styles'
import { DrawerSections } from './DrawerSections'
import { PlanSearchbarStyles } from './styles/toolbar.styles'

const searchBarWrapperStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: calc(15px + env(safe-area-inset-top)) 20px 15px;
  height: fit-content;
  border-bottom: 2px solid rgba(0, 0, 0, 0.5);
`

interface PlansHeaderSearchDrawerProps {
  initValue: string
  isOpen: boolean
  onDismiss: () => void
  onSearch: (search: string) => void
  clearSearch?: () => void
  sections?: { title: string; chips: string[] }[]
}

export function PlansHeaderSearchDrawer(props: PlansHeaderSearchDrawerProps) {
  const ref = React.useRef<HTMLIonSearchbarElement>(null)

  return (
    <IonModal
      isOpen={props.isOpen}
      onDidDismiss={props.onDismiss}
      onDidPresent={() => ref.current?.setFocus()}
      className={HideDesktopStyles}
    >
      <IonContent>
        <div className={searchBarWrapperStyles}>
          <IonSearchbar
            ref={ref}
            className={cx(PlanSearchbarStyles)}
            value={props.initValue}
            placeholder='Search'
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                props.onSearch(e.currentTarget.value!)
                props.onDismiss()
              }
            }}
          />
          <IonButton fill='clear' color={'dark'} onClick={props.onDismiss}>
            Cancel
          </IonButton>
        </div>
        <DrawerSections
          sections={props.sections}
          onSearch={(search) => {
            props.onSearch(search)
            props.onDismiss()
          }}
        />
      </IonContent>
    </IonModal>
  )
}
