import { css, cx } from '@emotion/css'
import { IonButton } from '@ionic/react'
import { useQuery } from 'react-query'
import { Swiper, SwiperSlide } from 'swiper/react'
import API from '../../../../api'
import { MOBILE_BREAKPOINT } from '../../../../utils/hooks/useWindowSize'
import { FlexCenter } from '../../../../utils/styles/flex-utils'
import { HideDesktopStyles, HideMobileStyles } from '../../../../utils/styles/ViewportUtils.styles'
import { HomeTitleStyles } from '../../styles/title-styles'
import { ViewMoreLinkStyles } from '../components/VireMoreLink'
import { BlogPost } from './BlogPost'
import { BlogPostObj } from './BlogPostObj'

const ViewMoreStoriesButtonStyles = css`
  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 40px;
  }
  .view-more-button {
    margin-top: 0;
  }
  & > a {
    width: 100%;
    max-width: 450px;
  }
`
function ViewMoreStoriesButton() {
  return (
    <div className={cx(FlexCenter, ViewMoreStoriesButtonStyles)}>
      <a href='https://www.gojimobile.com/blog' target='_blank' rel='noreferrer'>
        <IonButton className={cx(ViewMoreLinkStyles, 'view-more-button')}>View more stories</IonButton>
      </a>
    </div>
  )
}

const TopStoriesDesktopStyles = css`
  display: flex;
  flex-direction: column;
  gap: 48px;
`
function TopStoriesDesktop(props: { posts: BlogPostObj[] }) {
  return (
    <section className={cx(HideMobileStyles, TopStoriesDesktopStyles)}>
      {props.posts.map((post) => {
        return <BlogPost key={post.title} post={post} />
      })}
    </section>
  )
}

const TopStoriesMobileStyles = css`
  width: calc(var(--homepage-padding) * 2 + 100%);
  transform: translateX(calc(var(--homepage-padding) * -1));
  .swiper-slide {
    width: 73.9%;
  }
`
function TopStoriesMobile(props: { posts: BlogPostObj[] }) {
  return (
    <section className={cx(HideDesktopStyles, TopStoriesMobileStyles)}>
      <Swiper slidesOffsetBefore={24} spaceBetween={24} slidesPerView='auto'>
        {props.posts.map((post) => {
          return (
            <SwiperSlide key={post.title}>
              <BlogPost post={post} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section>
  )
}

export function TopStories() {
  const posts = useQuery({
    queryKey: ['blogposts'],
    async queryFn() {
      const result = await API.get('/blog/last-posts')
      return result as BlogPostObj[]
    }
  })

  return (
    <>
      <h2 className={HomeTitleStyles}>Top Stories</h2>
      <TopStoriesDesktop posts={posts.data ?? []} />
      <TopStoriesMobile posts={posts.data ?? []} />
      <ViewMoreStoriesButton />
    </>
  )
}
