import { css } from '@emotion/css'
import { MOBILE_BREAKPOINT_MAX } from '../../../../utils/hooks/useWindowSize'

export const PlanSearchbarStyles = css`
  --border-radius: 42px;
  --color: var(--ion-text-color);
  --placeholder-color: var(--ion-text-color);
  --placeholder-font-weight: 500;
  --box-shadow: none;

  .searchbar-input {
    padding-inline-start: 42px;
  }

  height: 40px;
  min-height: 40px;
  opacity: 0.8;

  transition: transform 0.3s, opacity 0.3s visibility 0.3s;
  &.state-collapsed {
    transform: translateY(-40px);
  }
  &.state-only-tabs {
    transform: translateY(-100%);
    opacity: 0 !important;
    visibility: hidden;
    transition: transform 0.3s, opacity 0.3s, visibility 0;
    transition-delay: 0, 0, 0.3s;
  }

  &.searchbar-has-focus,
  &.searchbar-has-value {
    opacity: 1;
  }
`

export const PlanHeaderSearchbarStyles = css`
  margin-bottom: 10px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
    width: 90%;
    margin-left: 5%;
  }
`
