import { Capacitor } from '@capacitor/core'
import { Geolocation, Position } from '@capacitor/geolocation'
import { css, cx } from '@emotion/css'
import { IonButton, IonFabButton, IonIcon, IonSpinner } from '@ionic/react'
import { addOutline, removeOutline } from 'ionicons/icons'
import * as React from 'react'
import shallow from 'zustand/shallow'
import { useBoundStore } from '../../store'
import usePermissionStore from '../../store/standalone/permission-store'
import { ShadowedStyles } from './TopBar/TopBar'

const SideBarStyles = css`
  position: relative;

  justify-self: end;
  margin-bottom: 8px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .location-spinner {
    width: 15px;
    height: 15px;
  }

  .sidebar-button {
    margin: 0;
  }
  .sidebar-button::part(native) {
    background-color: var(--ion-background-color);
    color: var(--ion-color-dark);
  }
`

const ZoomButtonsStyles = css`
  display: flex;
  flex-direction: column;
  background-color: var(--ion-background-color);
  border-radius: 60px;
  height: 81px;
  overflow: hidden;

  .zoom-button {
    font-size: 14px;
    width: 40px;
    flex-grow: 1;
    margin: 0;
  }

  .separator {
    height: 1px;
    background-color: var(--ion-color-light);
    margin: 0 8px;
  }
  .zoom-button::part(native) {
    background-color: transparent;
    padding: 0;
  }
`

const NetworkMapSidebar: React.FC<{
  onRecenter: () => void
  onZoomChanged: (newZoom: number) => void
  onCurrentLocation: (geolocation: Position) => void
  className?: string
}> = (props) => {
  const [setUserLocationAndAddress, loadingLocation] = useBoundStore(
    (state) => [state.setUserLocationAndAddress, state.loadingLocation],
    shallow
  )

  const [clearPermissionState] = usePermissionStore((state) => [state.clearPermissionState], shallow)

  function showLocationPermissionPopup() {
    clearPermissionState()
  }

  const onClickCurrentLocationButton = async () => {
    if (Capacitor.getPlatform() === 'android' && (await Geolocation.checkPermissions()).location !== 'granted') {
      showLocationPermissionPopup()
      return
    }

    const position = await setUserLocationAndAddress()
    if (loadingLocation) {
      return
    }

    if (position) {
      props.onCurrentLocation(position)
    }
  }

  return (
    <section className={cx(SideBarStyles, props.className)}>
      <IonFabButton
        onClick={onClickCurrentLocationButton}
        title='Current Location'
        className={cx(ShadowedStyles, 'sidebar-button', 'button')}
        color='light'
        size='small'
      >
        {loadingLocation ? (
          <IonSpinner className='location-spinner' color='medium' />
        ) : (
          <IonIcon color='medium' size='small' src='/assets/icons/location.svg'></IonIcon>
        )}
      </IonFabButton>
      <IonFabButton
        onClick={props.onRecenter}
        title='Recenter'
        className={cx(ShadowedStyles, 'sidebar-button', 'button')}
        color='light'
        size='small'
      >
        <IonIcon color='medium' src='/assets/icons/re-center.svg' size='small' />
      </IonFabButton>
      <div className={cx(ShadowedStyles, ZoomButtonsStyles)}>
        <IonButton onClick={() => props.onZoomChanged(1)} className='zoom-button' size='small' color='light'>
          <IonIcon size='24px' src={addOutline} />
        </IonButton>
        <div className='separator'></div>
        <IonButton onClick={() => props.onZoomChanged(-1)} className='zoom-button' size='small' color='light'>
          <IonIcon src={removeOutline} />
        </IonButton>
      </div>
    </section>
  )
}

export default NetworkMapSidebar
