import { css, cx } from '@emotion/css'
import { PlansHeaderStates } from './PlansHeader'
import { DrawerSections } from './DrawerSections'

const PlansHeaderSearchDrawerDesktopStyles = css`
  position: absolute;
  width: 100vw;
  top: 0;
  z-index: 100;
  color: var(--ion-color-dark);
  transition: transform 0.3s;
  background-color: #fff;
  padding: 0 var(--plans-tab-horizontal-padding);

  display: none;
  &.is-open {
    display: block;
    &.state-expanded {
      transform: translateY(184px);
    }
    &.state-collapsed {
      transform: translateY(144px);
    }
    &.state-only-tabs {
      transform: translateY(132px);
    }
  }
`

const backdropStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  &.is-open {
    display: block;
  }
`

interface PlansHeaderSearchDrawerDesktopProps {
  isOpen: boolean
  expandState: PlansHeaderStates
  onSearch: (search: string) => void
  onDismiss: () => void
  sections?: { title: string; chips: string[] }[]
}

export function PlansHeaderSearchDrawerDesktop(props: PlansHeaderSearchDrawerDesktopProps) {
  return (
    <>
      <div className={cx(PlansHeaderSearchDrawerDesktopStyles, props.expandState, { 'is-open': props.isOpen })}>
        <DrawerSections
          sections={props.sections}
          onSearch={(search) => {
            props.onSearch(search)
            props.onDismiss()
          }}
        />
      </div>
      <div className={cx(backdropStyles, { 'is-open': props.isOpen })} onClick={() => props.onDismiss()}></div>
    </>
  )
}
