import * as React from 'react'
import throttle from 'lodash/throttle'
import { PlansHeaderStates } from './PlansHeader'
import { Address } from '../../../store/address'
import { useFlag } from '../../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../../utils/FeatureFlags'
import { useAutoChatStorage } from '../../../pages/llm/hooks/useChatStorage'

function getHeaderState(direction: 'up' | 'down'): PlansHeaderStates {
  if (direction === 'up') {
    return PlansHeaderStates.EXPANDED
  }

  return PlansHeaderStates.COLLAPSED
}

function getPlanHeaderState(direction: 'up' | 'down', selectedTab: number, wizardState: WizardState) {
  if (selectedTab === 1 && wizardState !== 'completed') {
    return PlansHeaderStates.ONLY_TABS
  }

  return getHeaderState(direction)
}

export function useHeaderExpandedState() {
  const [headerState, setHeaderState] = React.useState<PlansHeaderStates>(PlansHeaderStates.EXPANDED)
  const [isOnTop, setIsOnTop] = React.useState(true)

  const handleMove = throttle((direction: 'up' | 'down') => {
    setHeaderState(getHeaderState(direction))
  }, 300)

  const handleReachTop = () => {
    setIsOnTop(true)
  }

  return {
    isOnTop,
    headerState,
    setHeaderState,
    handleMove,
    handleReachTop
  }
}

export type WizardState = 'stale' | 'in-progress' | 'completed'
// TODO: deprecate addresses
export function useWizardState(addresses: Address[]): WizardState {
  const chatUiEnabled = useFlag(FeatureFlag.CHAT_UI)
  const { chatStorage } = useAutoChatStorage()

  if (!chatUiEnabled) {
    return addresses.length === 0 ? 'in-progress' : 'completed'
  }

  if (!chatStorage.data) {
    return 'stale'
  }

  if (chatStorage.data.chatStatus === 'ended') {
    return 'completed'
  }

  if (chatStorage.data.chatTimeline.length > 1) {
    return 'in-progress'
  }
  return 'stale'
}

export function usePlansPageExpandedState(selectedTab: number, addresses: Address[]) {
  const { headerState, setHeaderState, isOnTop, handleReachTop } = useHeaderExpandedState()
  const wizardState = useWizardState(addresses)

  const handleMove = (direction: 'up' | 'down') => {
    setHeaderState(getPlanHeaderState(direction, selectedTab, wizardState))
  }

  const onSlideChange = (tabIndex: number) => {
    if (tabIndex !== 1) {
      setHeaderState(PlansHeaderStates.EXPANDED)
    } else if (wizardState !== 'completed') {
      setHeaderState(PlansHeaderStates.ONLY_TABS)
    } else {
      setHeaderState(PlansHeaderStates.EXPANDED)
    }
  }

  return {
    isOnTop,
    headerState,
    handleMove,
    handleReachTop,
    onSlideChange
  }
}
