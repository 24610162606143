import React from 'react'
import { cx } from '@emotion/css'
import { useHistory } from 'react-router'
import { IonButton, IonHeader, IonImg, IonRouterLink, IonRow } from '@ionic/react'
import { DesktopHeaderStyles, HeaderLinkStyles, ProfileLinkStyles } from './DesktopHeader.styles'
import useAuthStore from '../../../store/auth'
import { MainNavigationPaths } from '../../../paths'
import { useFlag } from '../../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../../utils/FeatureFlags'

interface HeaderLinkProps {
  path: string
  children: React.ReactNode
  className?: string
}
const HeaderLink: React.FC<HeaderLinkProps> = ({ children, path, className }) => {
  const history = useHistory()
  const isCurrentPath = history.location.pathname.includes(path)

  return (
    <IonRouterLink className={cx(HeaderLinkStyles, isCurrentPath ? 'active' : '', className)} routerLink={path}>
      {children}
    </IonRouterLink>
  )
}

export const DesktopHeader = (props: { className?: string }) => {
  const [currentUser] = useAuthStore((state) => [state.currentUser])
  const isUserLoggedIn = Boolean(currentUser?.token)

  const dealsFlag = useFlag(FeatureFlag.DEALS)

  return (
    <>
      <IonHeader
        className={cx(DesktopHeaderStyles, 'ion-justify-content-between ion-align-items-center', props.className)}
      >
        <IonRow className='ion-align-items-center navigation-links'>
          <IonRouterLink routerLink={MainNavigationPaths.home}>
            <IonImg src='/assets/images/goji.svg' alt='Goji logo' />
          </IonRouterLink>
          <HeaderLink path={MainNavigationPaths.plans}>Plans</HeaderLink>
          {dealsFlag ? <HeaderLink path={MainNavigationPaths.deals}>Promotions</HeaderLink> : null}
          <HeaderLink path={MainNavigationPaths.map}>Coverage Map</HeaderLink>
          <HeaderLink path={MainNavigationPaths.meter}>Network Meter</HeaderLink>
        </IonRow>
        <IonRow className='ion-align-items-center'>
          {isUserLoggedIn ? (
            <HeaderLink className={ProfileLinkStyles} path={MainNavigationPaths.profile}>
              <img title='Profile' src='/assets/icons/header/profile.svg' alt='profile' />
            </HeaderLink>
          ) : (
            <HeaderLink path={MainNavigationPaths.signIn}>Sign In</HeaderLink>
          )}
          <IonButton className='btn-download' shape='round' routerLink={MainNavigationPaths.meter}>
            Download&nbsp;
            <span className='unessential-when-tight'>Goji Mobile</span>
          </IonButton>
        </IonRow>
      </IonHeader>
    </>
  )
}
