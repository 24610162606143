import * as React from 'react'
import { css, cx } from '@emotion/css'
import { IonIcon, IonItem, IonPopover } from '@ionic/react'
import { chevronDown } from 'ionicons/icons'

const PopoverSelectStyles = css`
  border-radius: 12px;
  --inner-padding-end: 0;
  background-color: var(--ion-color-medium-contrast);
  text-align: left;

  &,
  &::part(native) {
    --min-height: 40px;
    height: 40px;
  }

  &::part(native) {
    padding: 0;
  }
  &.focused {
    border-color: var(--ion-color-primary);
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    width: 100%;
    height: 100%;
    pointer-events: none;
    color: var(--ion-color-medium);
  }

  .popover-placeholder {
    color: var(--ion-text-color);
    font-weight: 600;
  }
`

const SelectContext = React.createContext<{ selectedValue: string; onSelectedValueChange: (newValue: string) => void }>(
  { selectedValue: '', onSelectedValueChange: () => {} }
)

interface SelectItemProps extends React.ComponentProps<typeof IonItem> {
  value: any
}
export const SelectItem = (props: SelectItemProps) => {
  const { onSelectedValueChange } = React.useContext(SelectContext)

  return <IonItem mode='md' button onClick={() => onSelectedValueChange(props.value)} {...props} />
}

interface PopoverSelectProps {
  value: any
  placeholder: string
  onSelect: (newValue: any) => void
  children?: React.ReactNode | React.ReactNode[]
  displayValue: React.ReactNode | React.ReactNode[]
  className?: string
}
export const PopoverSelect = (props: PopoverSelectProps) => {
  const popoverRef = React.useRef<HTMLIonPopoverElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <IonItem
      onClick={(e) => {
        popoverRef.current!.event = e
        setIsOpen(true)
      }}
      lines='none'
      className={cx({ focused: isOpen }, PopoverSelectStyles, props.className)}
    >
      <div className='heading'>
        {props.value !== '' && props.value !== undefined ? (
          <div className='select-value'>{props.displayValue}</div>
        ) : (
          <div className='popover-placeholder'>{props.placeholder}</div>
        )}

        <IonIcon icon={chevronDown} />
      </div>
      <IonPopover
        reference='trigger'
        size='cover'
        ref={popoverRef}
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
      >
        <SelectContext.Provider value={{ selectedValue: props.value, onSelectedValueChange: props.onSelect }}>
          <div
            onClick={(event) => {
              event.stopPropagation()
              setIsOpen(false)
            }}
          >
            {props.children}
          </div>
        </SelectContext.Provider>
      </IonPopover>
    </IonItem>
  )
}
