import * as React from 'react'
import { css, cx } from '@emotion/css'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { FreeBulletsStyles } from '../../../components/Swiper/SwiperContent'
import { ArrowControls } from '../ArrowControls/ArrowControls'
import { PlanResultCardSkeletonV2 } from '../../../components/PlanResult/PlanResultCardSkeleton'
import { MOBILE_BREAKPOINT, useIsMobileSize } from '../../../utils/hooks/useWindowSize'

const HomeCaroulselStyles = css`
  position: relative;

  .swiper {
    @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
      --bullets-padding-top: 24px;
      --swiper-pagination-bullet-width: 11px;
      --swiper-pagination-bullet-height: 11px;
    }
    overflow: var(--carousel-overflow, visible);
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    width: fit-content;
  }
`
export function HomeCaroulsel(props: {
  className?: string
  loading?: boolean
  children: React.ReactNode | React.ReactNode[]
  showArrows?: boolean
  arrowControlsStyles?: string
}) {
  const swiperRef = React.useRef<SwiperRef>(null)
  const [swiperPosition, setSwiperPosition] = React.useState<number | undefined>(0)
  const isMobile = useIsMobileSize()

  return (
    <div className={cx(HomeCaroulselStyles, props.className)}>
      <Swiper
        slidesPerView={'auto'}
        className={`${FreeBulletsStyles(isMobile ? 24 : 46)}`}
        navigation
        pagination={{ clickable: true }}
        modules={[Pagination]}
        ref={swiperRef}
        spaceBetween={15}
        onTransitionEnd={() => setSwiperPosition(swiperRef.current?.swiper.progress)}
      >
        {props.loading ? (
          <>
            <SwiperSlide>
              <PlanResultCardSkeletonV2 />
            </SwiperSlide>
            <SwiperSlide>
              <PlanResultCardSkeletonV2 />
            </SwiperSlide>
            <SwiperSlide>
              <PlanResultCardSkeletonV2 />
            </SwiperSlide>
          </>
        ) : null}
        {props.children}
      </Swiper>
      {props.showArrows ? (
        <ArrowControls
          className={props.arrowControlsStyles}
          onForward={() => swiperRef.current?.swiper.slideNext()}
          onBackward={() => swiperRef.current?.swiper.slidePrev()}
          swiperPosition={swiperPosition}
        />
      ) : null}
    </div>
  )
}
