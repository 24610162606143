import { css, cx } from '@emotion/css'
import { IonRouterLink } from '@ionic/react'
import { RoutePaths } from '../../../paths'
import { stopPropagation } from '../../../utils/events/preventDefault'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { HideDesktopStyles } from '../../../utils/styles/ViewportUtils.styles'
import { IgnoreHomePadding } from '../map-section/HomeMapBase'

const FONT_SIZE_BREAKPOINT = 380

const PlanCalloutStyles = css`
  margin: 24px 0;
  color: var(--ion-text-color);

  width: calc(100% + var(--homepage-padding) * 2);

  .html-content {
    position: absolute;
    padding: 42px;
    display: flex;
    justify-content: space-between;
    gap: 13px;
    height: 100%;
  }

  .title {
    font-size: 22px;
    margin-bottom: 21px;

    @media screen and (max-width: ${FONT_SIZE_BREAKPOINT}px) {
      font-size: 16px;
    }
  }

  .barry-talking {
    min-height: 88px;

    max-width: 218px;
    transform: rotateY(180deg);
  }

  .background-svg,
  .html-content {
    width: 100%;
  }

  &,
  .background-svg {
    max-height: 285px;
    min-height: 206px;
  }
`

const FindMyPlanLinkStyles = css`
  color: var(--ion-text-color);
  text-decoration: underline;

  @media screen and (max-width: ${FONT_SIZE_BREAKPOINT}px) {
    font-size: 14px;
  }
`

export function PlanCallout(props: { onClick?: () => void }) {
  return (
    <IonRouterLink onClickCapture={props.onClick} className={HideDesktopStyles} routerLink={RoutePaths.chatFinder}>
      <section className={cx(IgnoreHomePadding, PlanCalloutStyles)}>
        <div className='html-content'>
          <div>
            <h2 className={cx(ResetMargin, 'title')}>Let’s find you a plan 50% cheaper with better coverage</h2>
            <IonRouterLink
              onClick={stopPropagation}
              className={FindMyPlanLinkStyles}
              routerLink={RoutePaths.chatFinder}
            >
              Find my plan
            </IonRouterLink>
          </div>
          <img
            className='barry-talking'
            src='/assets/images/barry/barry_talking.svg'
            alt='goji barry talking on the phone'
          />
        </div>
        <svg
          className='background-svg'
          xmlns='http://www.w3.org/2000/svg'
          preserveAspectRatio='none'
          viewBox='0 0 428 206'
          fill='none'
        >
          <rect width='428' height='206' fill='#ECF3FE' />
          <path d='M438 262L438 148L255 148C255 210.96 306.04 262 369 262L438 262Z' fill='#8CBAFF' />
          <path
            d='M438 262C438 199.04 386.96 148 324 148L255 148C255 210.96 306.04 262 369 262L438 262Z'
            fill='white'
            fillOpacity='0.25'
          />
          <path
            d='M71.5 -49L49.5 -49C-1.3102 -49 -42.5 -7.81019 -42.5 43L-20.5 43C30.3102 43 71.5 1.81019 71.5 -49Z'
            fill='#8CBAFF'
          />
        </svg>
      </section>
    </IonRouterLink>
  )
}
