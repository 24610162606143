import { css, cx } from '@emotion/css'
import { IonButton, IonIcon, IonRouterLink } from '@ionic/react'
import { arrowBack } from 'ionicons/icons'
import { useLocation } from 'react-router'
import { useOpenFrom } from './Header'

const BackButtonStyles = css`
  &::part(native) {
    padding: 0;
  }

  height: 32px;
  width: 32px;
  ion-icon {
    width: 32px;
    height: 32px;
  }
`

export function BackButton({ onClick }: { onClick: React.MouseEventHandler<HTMLIonButtonElement> }) {
  return (
    <IonButton mode='ios' onClick={onClick} color='primary' className={cx(BackButtonStyles, 'back-button')}>
      <IonIcon color='dark' src={arrowBack} />
    </IonButton>
  )
}

export function BackButtonLink({ backTo }: { backTo: string }) {
  const location = useLocation()
  const openFrom = useOpenFrom()

  if (backTo && typeof backTo !== 'string') {
    return <>{backTo}</>
  }

  return (
    <IonRouterLink
      routerLink={backTo === openFrom ? backTo : backTo + location.search}
      color='primary'
      className={cx(BackButtonStyles, 'back-button')}
    >
      <IonIcon color='dark' src={arrowBack} />
    </IonRouterLink>
  )
}
