import { css, cx } from '@emotion/css'
import { MOBILE_BREAKPOINT_MAX } from '../../../../utils/hooks/useWindowSize'
import { FlexCenter } from '../../../../utils/styles/flex-utils'
import { ResetMargin } from '../../../../utils/styles/MarginUtils.styles'
import { BlogPostObj } from './BlogPostObj'

const BlogPostStyles = css`
  display: flex;
  gap: 24px;

  .post-image-wrapper {
    min-width: min(453px, 50%);
    max-width: min(453px, 50%);
    height: 235px;
    position: relative;
    overflow: hidden;
    border-radius: 6.31px;
  }

  .post-image {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  .post-title {
    margin: 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .description {
    font-size: 18px;
    line-height: 27px;

    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
    flex-direction: column;

    .post-image-wrapper {
      height: 184.45px;
      max-width: 100%;
    }

    .post-title {
      font-size: 18px;
    }
    .description {
      -webkit-line-clamp: 3;
      font-size: 16px;
    }
  }
`

const BlogLinkStyles = css`
  text-decoration: none;
  color: var(--ion-text-color);
`
export function BlogPost(props: { post: BlogPostObj }) {
  return (
    <a className={BlogLinkStyles} href={props.post.blogUrl} target='_blank' rel='noreferrer'>
      <article className={BlogPostStyles}>
        <div className={cx(FlexCenter, 'post-image-wrapper')}>
          <img className='post-image' src={props.post.imageUrl} alt='blogpost' />
        </div>
        <div>
          <h4 className='post-title'>{props.post.title}</h4>
          <p className={cx(ResetMargin, 'description')}>{props.post.subtitle}</p>
        </div>
      </article>
    </a>
  )
}
