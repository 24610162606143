import React from 'react'
import { useHistory } from 'react-router'

export function patchQueryParams(searchParamsStr: string, value: any) {
  const searchParams = new URLSearchParams(searchParamsStr)
  for (const key of Object.keys(value)) {
    if (value[key] === undefined) {
      searchParams.delete(key)
    } else {
      searchParams.set(key, String(value[key as any]))
    }
  }
  return searchParams
}

export function useQueryParamsState<T extends Record<string, string | number | undefined | string[]>>(
  initialState: T
): [T, (value: Partial<T>) => void] {
  const history = useHistory()
  const initialStateRef = React.useRef(initialState)

  const state = React.useMemo(() => {
    const searchParams = new URLSearchParams(history.location.search)
    return { ...initialStateRef.current, ...Object.fromEntries(searchParams) }
  }, [history.location.search])

  const patchState = React.useCallback(
    (value: Partial<T>) => {
      const searchParams = patchQueryParams(history.location.search, value)
      history.replace({ ...history.location, pathname: window.location.pathname, search: searchParams.toString() })
    },
    [history]
  )

  return [state, patchState]
}
