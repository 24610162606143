import { css } from '@emotion/css'
import { MOBILE_BREAKPOINT_MAX } from '../../utils/hooks/useWindowSize'

export const ProfileMenuHeaderStyles = css`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  // Start / End icons
  .spacer {
    flex: 1;
  }
  .close-button {
    flex: 1;
    text-align: end;
    color: var(--goji-primary-black);
  }
`

export const ProfileHeaderStyles = css`
  box-shadow: none;
  --ion-background-color: var(--goji-primary-lime);
  ion-toolbar {
    --padding-end: 0;
  }
`
export const ProfileHeadingStyles = css`
  padding-top: 16px;
  padding-right: 24px;
  display: flex;
  justify-content: end;

  .close-button {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ProfileMenuPageStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .user-email {
    font-size: 18px;
  }

  .section-separator {
    height: 8px;
    margin: auto;
    width: 80%;
  }

  .content-heading {
    background-color: var(--goji-primary-lime);
    padding: 24px 24px 26px;
  }

  .welcome-profile-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 42px;
  }

  .welcome-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;

    h1 {
      font-size: 48px;
      font-weight: 900;
      margin: 0 0 8px;
    }

    h2 {
      font-size: 24px;
      font-weight: 900;
      margin: 0 0 16px;
    }

    ion-router-link {
      font-size: 18px;
      font-weight: 500;
      color: var(--ion-color-primary);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40%;

    img {
      min-width: 99px;
      width: 100%;
      transform: rotateY(180deg);
    }
  }

  .menu-profile-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .menu-profile-list {
    display: flex;
    flex-direction: column;
    list-style: none;
    width: 66%;
  }

  .menu-profile-item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid var(--ion-color-light);
    color: var(--ion-color-dark);
    gap: 16px;

    .menu-profile-item-title {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 16px;
    }

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: 18px;
      font-weight: 500;
    }
  }

  .menu-profile-item-arrow {
    display: flex;
    justify-self: end;
    img {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
    .welcome-profile-wrapper {
      margin-bottom: 0px;
      justify-content: flex-start;
      gap: 29px;

      @media (max-width: 400px) {
        gap: 10px;
      }
    }

    .welcome-info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 75%;

      h1 {
        font-size: 28px;
      }

      h2 {
        font-size: 20px;
      }
    }

    .image-wrapper {
      padding: 26px 0 0;
      max-width: 20%;
    }

    .section-separator {
      width: 100%;
    }

    .menu-profile-wrapper {
      margin-top: 0px;
    }

    .menu-profile-list {
      width: 100%;
    }

    .menu-profile-item {
      padding: 0 24px;
      grid-template-columns: 3fr 1fr;
    }

    .menu-profile-item-title {
      padding-left: 0px !important;
      img {
        width: 20px;
        height: 20px;
      }
    }

    .menu-profile-item-arrow {
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
`
