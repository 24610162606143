import { css, cx } from '@emotion/css'
import { IonSpinner } from '@ionic/react'
import shallow from 'zustand/shallow'
import { LazyPlansCarousel } from '../../../components/PlanResult/LazyPlansCarousel'
import { ProfilePaths } from '../../../paths'
import { useBoundStore } from '../../../store'
import { useGetPlansInfo } from '../../../utils/hooks/useGetPlansInfo'
import { FlexCenter } from '../../../utils/styles/flex-utils'
import { NoSavedPlansBanner } from '../../profile/SavedPlans'
import { ViewMoreLink } from './components/VireMoreLink'
import { MAX_CAROUSEL_LENGTH } from './constants/max_carousel_length'

const SavedPlansHeightStyles = css`
  height: 564px;
  margin-bottom: 24px;
`

const SavedPlansStyles = css`
  h4 {
    margin-bottom: 16px;
    margin-top: 16px;
    font-weight: 900;
    line-height: 22.5px;
  }
`

export function SavedPlans() {
  const [favoritePlanIds] = useBoundStore((state) => [state.favoritePlanIds], shallow)
  const { loading: isLoadingFavoritePlans, data: favoritePlans } = useGetPlansInfo(favoritePlanIds)

  if (isLoadingFavoritePlans) {
    return (
      <section className={cx(SavedPlansHeightStyles, FlexCenter)}>
        <IonSpinner />
      </section>
    )
  }

  if (favoritePlans.length === 0) {
    return <NoSavedPlansBanner />
  }

  return (
    <section className={cx(SavedPlansStyles, SavedPlansHeightStyles)}>
      <main>
        <LazyPlansCarousel plans={favoritePlans.slice(0, MAX_CAROUSEL_LENGTH)} />
        <div className={FlexCenter}>
          <ViewMoreLink routerLink={ProfilePaths.savedPlans}>View saved plans</ViewMoreLink>
        </div>
      </main>
    </section>
  )
}
