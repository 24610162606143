import { css, cx } from '@emotion/css'
import { IonIcon, IonLabel, IonRouterLink, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import { MainNavigationPaths, RoutePaths } from '../../paths'
import { ALL_PLANS_TAB, RECOMMENDED_TAB } from '../plans/tabs/PlanTab'
import { trackAppsFlyerEvent } from '../../plugin/AppsFlyerPlugin'
import * as React from 'react'
import { withSuspense } from './withSuspense'
import SummaryPage from '../summary/SummaryPage'
import { useIsMobileSize } from '../../utils/hooks/useWindowSize'
import { useFlag } from '../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../utils/FeatureFlags'
import { HomePageTransition } from '../home/home-transition/HomeTransition'

const PlansPage = withSuspense(React.lazy(() => import('../plans/PlansPage')))
const MapPage = withSuspense(React.lazy(() => import('../map/MapPage')))
const MeterPage = withSuspense(React.lazy(() => import('../meter/MeterPage')))
const SignInPage = withSuspense(React.lazy(() => import('../sign-in/SignIn')))
const SignUpPage = withSuspense(React.lazy(() => import('../sign-in/SignUp')))
const DealsPage = withSuspense(React.lazy(() => import('../deals/DealsPage')))

function getIconUrl(icon: string, dark: boolean) {
  return `/assets/icons/footer/${dark ? 'dark/' : ''}${icon}.svg`
}

function ActivatableIcon(props: { icon: string; active: boolean }) {
  return (
    <>
      <IonIcon
        className='tab-icon'
        style={{ display: !props.active ? 'none' : 'block' }}
        icon={getIconUrl(props.icon, true)}
      />
      <IonIcon
        className='tab-icon'
        style={{ display: props.active ? 'none' : 'block' }}
        icon={getIconUrl(props.icon, false)}
      />
    </>
  )
}

function trackMapClick() {
  trackAppsFlyerEvent({ eventName: 'af_network_map_interaction' })
}

const TabBarStyles = css`
  width: 100%;
  --border: 1px solid var(--ion-tab-bar-border-color);
  --ion-tab-bar-color: #4d4d4d;

  ion-tab-button {
    font-size: 10px;
  }

  .tab-icon {
    width: 24px;
    height: 24px;
  }

  .tab-selected ion-label {
    font-weight: 700;
  }

  &.is-desktop {
    display: none;
  }

  .link-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4px;
    margin-bottom: 1px;
    color: var(--ion-tab-bar-color);
  }
`

export function MainNavigation(props: RouteComponentProps) {
  const isMobile = useIsMobileSize()
  const dealsFlag = useFlag(FeatureFlag.DEALS)

  return (
    <IonTabs>
      <IonRouterOutlet ionPage>
        <Switch>
          <Route
            exact
            path={[MainNavigationPaths.home, MainNavigationPaths.profile]}
            render={() => <HomePageTransition />}
          />
          <Route exact path={MainNavigationPaths.meter} render={() => <MeterPage />} />
          <Route
            exact
            path={MainNavigationPaths.plans}
            render={() => <Redirect to={MainNavigationPaths.plans + '/' + ALL_PLANS_TAB} />}
          />
          <Route
            exact
            path={MainNavigationPaths.plans + '/' + ALL_PLANS_TAB + '/:carrierId/:planId'}
            render={() => <SummaryPage />}
          />
          <Route exact path={MainNavigationPaths.plans + '/:tab'} render={() => <PlansPage />} />
          <Route exact path={MainNavigationPaths.map} component={() => <MapPage />} />
          <Route exact path={MainNavigationPaths.signIn} component={() => <SignInPage />} />
          <Route exact path={MainNavigationPaths.createAccount} component={() => <SignUpPage />} />
          <Route exact path={MainNavigationPaths.deals} component={() => <DealsPage />} />
          <Route
            exact
            path={MainNavigationPaths.results}
            render={() => <Redirect to={MainNavigationPaths.plans + '/' + RECOMMENDED_TAB} />}
          />
          <Route path='/' render={() => <Redirect to='/home' />} />
        </Switch>
      </IonRouterOutlet>

      <IonTabBar
        key={props.location.pathname}
        className={cx(TabBarStyles, isMobile ? 'custom-width-limiter' : 'is-desktop')}
        slot='bottom'
      >
        <IonTabButton tab='home' href={MainNavigationPaths.home}>
          <ActivatableIcon
            icon='home'
            active={
              props.location.pathname === MainNavigationPaths.home ||
              props.location.pathname === MainNavigationPaths.root
            }
          />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab='plans' href={MainNavigationPaths.plans}>
          <ActivatableIcon
            icon='plans'
            active={
              props.location.pathname.startsWith(MainNavigationPaths.plans) ||
              props.location.pathname === MainNavigationPaths.results
            }
          />
          <IonLabel>Plans</IonLabel>
        </IonTabButton>
        {dealsFlag ? (
          <IonTabButton tab='deals' href={MainNavigationPaths.deals}>
            <ActivatableIcon icon='deals' active={props.location.pathname === MainNavigationPaths.deals} />
            <IonLabel>Promotions</IonLabel>
          </IonTabButton>
        ) : null}
        <IonTabButton onClick={trackMapClick} tab='map' href={MainNavigationPaths.map}>
          <ActivatableIcon icon='map' active={props.location.pathname === MainNavigationPaths.map} />
          <IonLabel>Map</IonLabel>
        </IonTabButton>
        <IonTabButton tab='meter' href={MainNavigationPaths.meter}>
          <ActivatableIcon icon='meter' active={props.location.pathname === MainNavigationPaths.meter} />
          <IonLabel>Meter</IonLabel>
        </IonTabButton>
        <IonTabButton>
          <IonRouterLink routerLink={RoutePaths.chatFree}>
            <div className='link-inner'>
              <ActivatableIcon icon='chat' active={false} />
              <IonLabel>Chat</IonLabel>
            </div>
          </IonRouterLink>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}
