import { css } from '@emotion/css'
import shallow from 'zustand/shallow'
import { MainNavigationPaths, RoutePaths } from '../../../paths'
import { UseCarrier } from '../../../services/Carrier.service'
import { useBoundStore, useGeneralStore } from '../../../store'
import { useFlag } from '../../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../../utils/FeatureFlags'
import { useIsInitialTimeline } from '../../llm/hooks/useChatStorage'
import { BestCarrierCard } from './BestCarrierCard'
import { JustForYouCard } from './JustForYouCard'
import { useResumePage } from './useResumePage'
import { trackAmplitudeEvent } from '../../../utils/amplitude'
import { PlanCallout } from '../PlanCallout/PlanCallout'
import { PlanCalloutDesktop } from '../PlanCallout/PlanCalloutDesktop'
import { HomePlanTabs } from './HomePlabTab'
import { BrowsePlans } from './BrowsePlans'
import { TrendingPromotions } from './trending-promotions/TrendingPromotions'
import { TopStories } from './top-stories/TopStories'
import { UsePlan } from '../../../services/planService/Plan.service'

const handleOnClickTrackStartEvent = () => {
  trackAmplitudeEvent('ChatBarry - Start Plan Finding')
}

const handleOnClickTrackResumeEvent = () => {
  trackAmplitudeEvent('ChatBarry - Resume Plan Finding')
}

function PlanFlowSwitch() {
  const resumePage = useResumePage()
  const chatEnabled = useFlag(FeatureFlag.CHAT_UI)

  const isInitialTimeline = useIsInitialTimeline()

  if (chatEnabled ? isInitialTimeline : resumePage === RoutePaths.carrierSelector) {
    return (
      <>
        <PlanCallout onClick={handleOnClickTrackStartEvent} />
        <PlanCalloutDesktop onClick={handleOnClickTrackStartEvent} />
      </>
    )
  }

  if (resumePage === MainNavigationPaths.results) {
    return (
      <div className='plan-flow-switch'>
        <HomePlanTabs />
      </div>
    )
  }

  return (
    <>
      <PlanCallout onClick={handleOnClickTrackResumeEvent} />
      <PlanCalloutDesktop onClick={handleOnClickTrackResumeEvent} />
    </>
  )
}

export function MapCardSwitch() {
  const bestCarrier = UseCarrier.useBestCarrier()
  const bestCarriers = UseCarrier.useBestCarriers()
  const [addresses] = useBoundStore((state) => [state.addresses], shallow)

  if (!bestCarriers.isLoading && !bestCarrier) {
    return (
      <JustForYouCard
        title='Experiencing dropped calls?'
        description='Check to see how good (or bad) coverage is across carriers for your favorite locations.'
        imageAlt='screenshot of the coverage map'
        logo_url='/assets/images/welcome/dropped-calls-background.jpg'
        linkText='View my coverage'
        link={MainNavigationPaths.map}
      />
    )
  }

  return <BestCarrierCard isLoading={bestCarriers.isLoading} carrier={bestCarrier} addresses={addresses} />
}

const JustForYouStyles = css`
  padding-top: 24px;

  .plan-flow-switch {
    .swiper-slide {
      max-width: 392px;
    }
  }

  .ul-sections {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .better-plan-image-container {
    width: 121px;
    min-height: 182px;
    padding-top: 18px;
  }

  .network-performance-image-container {
    width: 119px;
    min-height: 156px;
  }

  @media screen and (min-width: 768px) {
    padding: 0 var(--homepage-desktop-padding);
    padding-top: 56px;
    .ul-sections {
      gap: 56px;
    }
  }
`

export function JustForYou() {
  const plans = UsePlan.useAllPlans()
  const favoritePlanIds = useGeneralStore((state) => state.favoritePlanIds)

  const favoritePlans = (plans.data ?? []).filter((plan) => favoritePlanIds.includes(plan.planId))

  return (
    <section className={JustForYouStyles}>
      <ul className='ul-sections'>
        <TrendingPromotions favoritePlans={favoritePlans} />
        <PlanFlowSwitch />
        <BrowsePlans />
        <TopStories />
      </ul>
    </section>
  )
}
