import { css, cx } from '@emotion/css'
import { IonRouterLink } from '@ionic/react'
import { MainNavigationPaths } from '../../../paths'
import { MOBILE_BREAKPOINT_MAX } from '../../../utils/hooks/useWindowSize'
import { FlexCenter } from '../../../utils/styles/flex-utils'
import { HomeTitleStyles } from '../styles/title-styles'
import { ViewMoreLink } from './components/VireMoreLink'

const BrowseLiStyles = css`
  color: var(--ion-text-color);
  &,
  .left-content {
    display: flex;
    align-items: center;
  }

  font-size: 20px;
  justify-content: space-between;
  border-bottom: 1px solid var(--ion-color-light);

  height: 88px;

  .left-content {
    gap: 16px;
  }

  .arrow {
    color: white;
    width: 24px;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
    font-size: 16px;
    height: 56px;

    .arrow {
      height: 15px;
      width: 15px;
    }

    .image {
      width: 44px;
    }
  }
`
function BrowseLi(props: { children: React.ReactNode; image: string; imageAlt: string; link: string }) {
  return (
    <IonRouterLink routerLink={props.link}>
      <li className={BrowseLiStyles}>
        <span className='left-content'>
          <img className='image' src={props.image} alt={props.imageAlt} /> {props.children}
        </span>
        <img className='arrow' alt='arrow pointing right' src='/assets/icons/arrow_next.svg' />
      </li>
    </IonRouterLink>
  )
}

const BrowsePlansStyles = css`
  margin-top: 32px;
  .browse-plans {
    margin-bottom: 24px;
  }
  .browse-ul {
    list-style-type: none;
    padding: 0;
  }
`

export function BrowsePlans() {
  return (
    <section className={BrowsePlansStyles}>
      <h2 className={cx(HomeTitleStyles, 'browse-plans')}>Browse Plans</h2>

      <ul className='browse-ul'>
        <BrowseLi
          link={MainNavigationPaths.plans + '/0'}
          image='/assets/icons/home/top_prepaid.svg'
          imageAlt='dollar bill icon'
        >
          Top prepaid plans
        </BrowseLi>
        <BrowseLi
          link={MainNavigationPaths.plans + '/1'}
          image='/assets/icons/home/popular_unlimited.svg'
          imageAlt='phone with unlimited sign icon'
        >
          Popular unlimited plans
        </BrowseLi>
        <BrowseLi
          link={MainNavigationPaths.plans + '/2'}
          image='/assets/icons/home/fresh_picks.svg'
          imageAlt='star icon'
        >
          Fresh picks
        </BrowseLi>
      </ul>
      <div className={FlexCenter}>
        <ViewMoreLink routerLink={MainNavigationPaths.plans}>View more plans</ViewMoreLink>
      </div>
    </section>
  )
}
