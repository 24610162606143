import { css, cx } from '@emotion/css'
import React from 'react'
import CarrierDisplay from '../../components/CarrierDisplay/CarrierDisplay'
import { getCarrierLogoUrl } from '../../services/carrier/get-logo-url'
import { trackAmplitudeEvent } from '../../utils/amplitude'
import { Deal } from './deal.service'
import DealExpirationText from './DealExpirationText'
import { DealModalSingleton } from './DealModalSingleton'

const DealCardStyles = css`
  background-color: var(--ion-background-color);
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid var(--ion-color-light);
  border-radius: 10px;
  padding: 16px;
  overflow: hidden;
  box-shadow: 0px 0px 54px 0px rgba(178, 119, 60, 0.1), 0px 8px 8px 0px rgba(18, 46, 80, 0.06),
    0px 1px 6px 0px rgba(24, 35, 48, 0.18);

  display: flex;
  align-items: center;
  gap: 10px;

  .deal-image {
    max-width: 38%;
    min-width: fit-content;
    display: flex;
    justify-content: center;
  }

  .deal-card-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: 100%;
  }

  .deal-card-title {
    color: var(--ion-color-medium);
    font-size: 14px;
  }

  .description {
    overflow: hidden;
  }
`

type DealCardProps = {
  deal: Deal
  onClick: (deal: Deal) => void
  className?: string
}
export function DealCard(props: DealCardProps) {
  return (
    <a
      onClick={(evt) => {
        if (!DealModalSingleton.hasSeenModal) {
          trackAmplitudeEvent('Deals - show deal modal', { deal: props.deal })
          DealModalSingleton.hasSeenModal = true
          evt.preventDefault()
          props.onClick(props.deal)
        } else {
          trackAmplitudeEvent('Deals - navigated to carrier website', { deal: props.deal, from: 'deals page' })
        }
      }}
      href={props.deal.url}
      target='_blank'
      rel='noreferrer'
      className={cx(DealCardStyles, props.className)}
    >
      <div className='deal-image'>
        <CarrierDisplay
          carrier={{ name: props.deal.carrierName, logo_url: getCarrierLogoUrl(props.deal.carrierLogoName) }}
        />
      </div>
      <section className='deal-card-content'>
        <span className='deal-card-title'>{props.deal.carrierName}</span>
        <div className={cx('description')}>{props.deal.description}</div>

        <DealExpirationText dealExpiration={props.deal.expirationDate} />
      </section>
    </a>
  )
}
