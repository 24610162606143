import { Geolocation } from '@capacitor/geolocation'
import { useGeneralStore } from '..'
import { getAddressFromGeolocation } from '../../utils/Address'
import { Logger } from '../../utils/Logger'

export async function initCurrentAddress() {
  if (useGeneralStore.getState().currentAddress) {
    return
  }

  try {
    const hasPermission = await Geolocation.checkPermissions()

    if (hasPermission.location === 'granted') {
      useGeneralStore.setState({ loadingLocation: true })
      const position = await Geolocation.getCurrentPosition()
      const geolocationAddress = await getAddressFromGeolocation(position)

      useGeneralStore.setState({ loadingLocation: false, currentAddress: geolocationAddress })
    }
  } catch (error) {
    useGeneralStore.setState({ loadingLocation: false, currentAddressError: true })
    Logger.error(error)
  }
}
