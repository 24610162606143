import { useIsMobileSize } from '../../utils/hooks/useWindowSize'
import { DesktopHeader } from '../home/DesktopHeader/DesktopHeader'
import { IonButton, IonContent, IonFabButton, IonIcon } from '@ionic/react'
import React from 'react'
import { useBoundStore } from '../../store'
import { MainNavigationPaths } from '../../paths'
import { DesktopFooter } from '../home/DesktopFooter/DesktopFooter'
import {
  NoSavedPlansBannerStyles,
  NoSavedPlansStyles,
  SavedPlansFooterWrapper,
  SavedPlansHeaderStyles,
  SavedPlansStyles
} from './SavedPlans.styles'
import { useGetPlansInfo } from '../../utils/hooks/useGetPlansInfo'
import { cx } from '@emotion/css'
import { ALL_PLANS_TAB, RECOMMENDED_TAB } from '../plans/tabs/PlanTab'
import { PlansListTab } from '../plans/tabs/PlansListTab'
import { PlansHeader, PlansHeaderStates } from '../../components/Header/PlansHeader/PlansHeader'
import { VirtuosoHandle } from 'react-virtuoso'
import {
  CompareButtonsStyles,
  PlansPageDesktopHeaderStyles,
  PlansPageFooterWrapper,
  PlansPageHeaderStyles
} from '../plans/PlansPage.styles'
import { SwiperRef } from 'swiper/react'
import { HideMobileStyles } from '../../utils/styles/ViewportUtils.styles'
import { SortingOption } from '../../components/Header/PlansHeader/PlansHeaderChips'
import { CompareToolbar } from '../../components/BestPlans/CompareToolbar'
import { CompareFooter } from '../network-quality/ResultsPage'
import { sortPlans } from '../../services/planService/Plan.service'
import { filterPlans, searchPlans } from '../../services/planService/Plan.utils'
import { useHeaderExpandedState } from '../../components/Header/PlansHeader/useExpandedState'
import { BackButtonLink } from '../../components/Header/BackButton'
import { useNavigateToPlan } from '../../utils/hooks/useNavigateToPlan'
import { chevronUp } from 'ionicons/icons'
import { usePlansFilterState } from '../plans/usePlansFilterState'
import shallow from 'zustand/shallow'
import useFiltersStore from '../../store/standalone/filters-store/filters-store'
import { ResetMargin } from '../../utils/styles/MarginUtils.styles'

interface NoSavedPlansProps {
  className?: string
}

export function NoSavedPlansBanner() {
  return (
    <article className={NoSavedPlansBannerStyles}>
      <div className='barry_image_wrapper'>
        <img src='/assets/images/barry/barry_sitting2.svg' alt='No saved plans' />
      </div>
      <div className='info_no_saved_plans_wrapper'>
        <h1>No saved plans</h1>
        <p>Your favorited plans will show up here.</p>
      </div>
    </article>
  )
}

const NoSavedPlans = ({ className }: NoSavedPlansProps) => {
  return (
    <section className={cx(NoSavedPlansStyles, className)}>
      <NoSavedPlansBanner />
      <aside className='buttons_no_saved_plans_wrapper'>
        <IonButton
          className={cx(ResetMargin, 'recommended_plans_btn ion-text-wrap')}
          shape='round'
          routerLink={`${MainNavigationPaths.plans}/${RECOMMENDED_TAB}`}
        >
          Go to recommended plans
        </IonButton>
        <IonButton
          className={cx(ResetMargin, 'btn-secondary-outline all_plans_btn ion-text-wrap')}
          shape='round'
          routerLink={`${MainNavigationPaths.plans}/${ALL_PLANS_TAB}`}
        >
          Browse all plans
        </IonButton>
      </aside>
    </section>
  )
}

const SavedPlans = () => {
  const isMobile = useIsMobileSize()

  const [favoritePlanIds, compareSelection] = useBoundStore(
    (state) => [state.favoritePlanIds, state.compareSelection],
    shallow
  )
  const { loading: isLoadingFavoritePlans, data: favoritePlansData } = useGetPlansInfo(favoritePlanIds)

  const noSavedPlans = favoritePlansData.length === 0

  const headerSwiperRef = React.useRef<SwiperRef>(null)
  const virtuosoRef = React.createRef<VirtuosoHandle>()
  const contentRef = React.useRef<HTMLIonContentElement>(null)

  const { headerState, handleMove, setHeaderState } = useHeaderExpandedState()
  const [isOnTop, setIsOnTop] = React.useState(true)

  const { search, setSearch } = usePlansFilterState()

  const nLinesFilter = useFiltersStore((state) => state.numLines) || 1
  const setNLinesFilter = React.useCallback((numLines: number) => {
    useFiltersStore.setState({ numLines })
  }, [])

  const [sortSavedPlans, setSortSavedPlans] = React.useState<{ [key: number]: SortingOption }>({
    0: SortingOption.Featured
  })

  const [showCompare, setShowCompare] = React.useState(compareSelection.size > 0)

  const handleScrollToTop = () => {
    const virtuoso = virtuosoRef.current
    if (virtuoso) {
      virtuoso.scrollToIndex({ index: 0, align: 'start', behavior: 'smooth' })
      handleMove('up')
    }
  }

  const handleReachTop = () => {
    setIsOnTop(true)
  }

  React.useEffect(() => {
    if (!isLoadingFavoritePlans && favoritePlansData.length === 0) {
      setHeaderState(PlansHeaderStates.ONLY_TABS)
    }
  }, [favoritePlansData, isLoadingFavoritePlans, setHeaderState])

  const filteredAndSortedPlans = React.useMemo(() => {
    if (!favoritePlansData || !favoritePlansData.length) return []

    let filteredPlans = [...favoritePlansData]

    if (filterPlans.length) {
      filteredPlans = searchPlans(filteredPlans, search)
      sortPlans(filteredPlans, sortSavedPlans[0])
      filteredPlans = filterPlans(filteredPlans, { nLinesFilter })
    }

    return filteredPlans
  }, [favoritePlansData, nLinesFilter, search, sortSavedPlans])

  const { navigateToPlan, planScrollIdx } = useNavigateToPlan()

  return (
    <>
      <DesktopHeader className={cx(HideMobileStyles, PlansPageDesktopHeaderStyles)} />
      <PlansHeader
        className={cx(PlansPageHeaderStyles, SavedPlansHeaderStyles)}
        tabs={['Saved Plans']}
        tab={-1}
        ref={headerSwiperRef}
        onTabChange={() => {}}
        expandedState={headerState}
        isOnTop={isOnTop}
        search={search}
        onSearch={(search) => {
          setSearch(search)
        }}
        sortByPerTab={sortSavedPlans || {}}
        onSort={(sortBy, tab) => {
          setSortSavedPlans({ ...sortSavedPlans, [tab]: sortBy })
        }}
        nLinesFilter={nLinesFilter}
        onFilter={setNLinesFilter}
      >
        {isMobile && (
          <div className={cx(headerState, 'back-arrow')}>
            <BackButtonLink backTo={MainNavigationPaths.profile} />
          </div>
        )}
      </PlansHeader>

      <IonContent className={cx(SavedPlansStyles)} ref={contentRef}>
        {!noSavedPlans || isLoadingFavoritePlans ? (
          <PlansListTab
            onPlanClick={navigateToPlan}
            initialScrollIndex={planScrollIdx}
            plans={filteredAndSortedPlans || []}
            headerState={headerState}
            isLoading={isLoadingFavoritePlans}
            onMove={handleMove}
            onReachTop={handleReachTop}
            compareView={showCompare}
            virtuosoRef={virtuosoRef}
          />
        ) : (
          <NoSavedPlans className='no_saved_plans' />
        )}
      </IonContent>
      <footer className={cx(PlansPageFooterWrapper, SavedPlansFooterWrapper)}>
        {!noSavedPlans && (
          <>
            <CompareToolbar
              showCompareButton={!showCompare}
              className={cx(CompareButtonsStyles)}
              toggleCompare={() => setShowCompare(!showCompare)}
            >
              <IonFabButton title='Back to the top' onClick={handleScrollToTop}>
                <IonIcon icon={chevronUp} />
              </IonFabButton>
            </CompareToolbar>
            {showCompare ? (
              <CompareFooter setCompareView={setShowCompare} />
            ) : (
              <DesktopFooter className={HideMobileStyles} />
            )}
          </>
        )}
      </footer>
    </>
  )
}

export default SavedPlans
