import React from 'react'
import shallow from 'zustand/shallow'
import { useBoundStore } from '../../../store'
import { Logger } from '../../../utils/Logger'

export function useSetUserLocation() {
  const [currentAddress, setUserLocationAndAddress, loadingLocation] = useBoundStore(
    (state) => [state.currentAddress, state.setUserLocationAndAddress, state.loadingLocation],
    shallow
  )

  const setUserLocation = React.useCallback(async () => {
    try {
      const result = await setUserLocationAndAddress()
      return result?.address
    } catch (e) {
      Logger.error(e)
    }
  }, [setUserLocationAndAddress])

  return {
    currentAddress,
    setUserLocation,
    loadingLocation
  }
}
