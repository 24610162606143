import { css, cx } from '@emotion/css'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { HideDesktopStyles } from '../../../utils/styles/ViewportUtils.styles'

export const HomeMapBaseHeight = css`
  height: 219px;
`
export const IgnoreHomePadding = css`
  position: relative;
  width: calc(100% + var(--homepage-padding) * 2);
  right: var(--homepage-padding);
`

const HomeMapGeneralStyles = (
  options: Pick<HomeMapBaseProps, 'backgroundImage' | 'overlayColor' | 'overlayOpacity'>
) => css`
  display: flex;
  justify-content: space-between;

  background-image: url(${options.backgroundImage});
  background-size: cover;

  padding: 36px 18px;

  & > * {
    z-index: 2;
    position: relative;
  }

  color: white;

  .goji-image {
    max-width: 30%;
    min-width: 100px;
  }

  .widget-title-wrapper {
    flex-grow: 1;
  }

  .widget-title {
    font-size: 28px;
  }
  .widget-subtitle {
    margin-top: 4px;
  }

  @media screen and (max-width: 360px) {
    .widget-subtitle {
      display: none;
    }
  }

  &::before {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: ${options.overlayColor};
    ${options.overlayOpacity ? `opacity: ${options.overlayOpacity};` : ''}
  }
`

type HomeMapBaseProps = {
  backgroundImage: string
  sideImage: string
  sideImageAlt: string
  overlayColor: string
  overlayOpacity?: string

  title: string
  subtitle: string
  children?: React.ReactNode
}
function HomeMapBase(props: HomeMapBaseProps) {
  return (
    <section className={cx(HideDesktopStyles, IgnoreHomePadding, HomeMapBaseHeight, HomeMapGeneralStyles(props))}>
      <div className='widget-title-wrapper'>
        <h1 className={cx(ResetMargin, 'widget-title')}>{props.title}</h1>
        <div className='widget-subtitle'>{props.subtitle}</div>

        {props.children}
      </div>
      <img className='goji-image' src={props.sideImage} alt={props.sideImageAlt} />
    </section>
  )
}

export default HomeMapBase
