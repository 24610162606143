import React from 'react'
import { useHistory } from 'react-router'
import { History } from 'history'
import { RoutePaths, MainNavigationPaths } from '../../paths'
import { RumSingleton } from '../../utils/aws-rum'
import { Logger } from '../../utils/Logger'

interface ErrorBoundaryClassProps {
  children: React.ReactNode
  history: History<unknown>
}
export class ErrorBoundaryClass extends React.Component<ErrorBoundaryClassProps> {
  render() {
    return this.props.children
  }

  static getDerivedStateFromError() {}

  componentDidCatch(error: Error, _errorInfo: React.ErrorInfo) {
    // avoid infinite loop when error occurs on the home page. Fall back to profile page
    if (this.props.history.location.pathname === MainNavigationPaths.home) {
      this.props.history.push(MainNavigationPaths.profile)
    } else {
      this.props.history.push(RoutePaths.root)
    }

    try {
      if (RumSingleton.rum) {
        RumSingleton.rum.recordError(error)
      }
    } catch (e) {
      Logger.error('ErrorBoundary', 'Failed to record error', e)
    }
  }
}

export function ErrorBoundary(props: Omit<ErrorBoundaryClassProps, 'history'>) {
  const history = useHistory()
  return <ErrorBoundaryClass {...props} history={history} />
}
