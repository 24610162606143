import { css, cx } from '@emotion/css'
import { IonRow, IonText, IonToolbar } from '@ionic/react'

const DesktopFooterStyles = css`
  overflow: visible;
  width: 100%;
  height: 64px;
  display: flex;
  padding: 0 8.5%;
  box-shadow: 0px -1px 0px 0px #00000040;
  z-index: 5;
`

export const DesktopFooter = (props: { className?: string }) => {
  const currentYear = new Date().getFullYear()

  return (
    <IonToolbar className={cx(DesktopFooterStyles, 'ion-align-items-center', props.className)}>
      <IonRow className='ion-justify-content-between'>
        <IonText color='dark'>@{currentYear} Goji Mobile</IonText>
        <div>
          <a
            className='ion-margin-end ion-padding-end'
            href='https://www.gojimobile.com/terms-conditions'
            target='_blank'
            rel='noreferrer'
          >
            <IonText color='dark'>Terms of Service</IonText>
          </a>
          <a href='https://www.gojimobile.com/privacy-policy' target='_blank' rel='noreferrer'>
            <IonText color='dark'>Privacy</IonText>
          </a>
        </div>
      </IonRow>
    </IonToolbar>
  )
}
