import { Geolocation } from '@capacitor/geolocation'
import { css, cx } from '@emotion/css'
import { IonButton, IonRouterLink } from '@ionic/react'
import React from 'react'
import shallow from 'zustand/shallow'
import InnerCoverageMap from '../../../components/CoverageMap/CoverageMapComponent/InnerCoverageMap'
import { useCoverageMapLogic } from '../../../components/CoverageMap/CoverageMapComponent/useCoverageMap'
import { useMarkers } from '../../../components/Marker'
import { MainNavigationPaths } from '../../../paths'
import { UseCarrier } from '../../../services/Carrier.service'
import { useGeneralStore } from '../../../store'
import { Address } from '../../../store/address'
import { getAddressFromGeolocation } from '../../../utils/Address'
import { preventDefault } from '../../../utils/events/preventDefault'
import { HideDesktopStyles } from '../../../utils/styles/ViewportUtils.styles'
import { HomeMapBaseHeight, IgnoreHomePadding } from './HomeMapBase'

const HomeMapWithLocationStyles = css`
  height: 300px;

  #map {
    height: 100%;
  }

  .indicator-5g {
    position: absolute;
    top: 12px;
    left: 0;
    color: var(--ion-color-medium-contrast);
    background-color: var(--ion-color-medium);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    display: flex;

    padding: 8.5px 16px;

    .separator {
      margin: 3px 8px;
      border-left: 1px solid var(--ion-background-color);
      opacity: 0.3;
    }
  }

  .map-link {
    --border-radius: 100%;
    --padding-top: 0;
    --padding-end: 0;
    --padding-start: 0;
    --padding-bottom: 0;
    --background: var(--ion-background-color);

    --box-shadow: 0px 0px 54px 0px #3c71b21a, 0px 8px 8px 0px #122e500f, 0px 1px 6px 0px #1823302e;

    position: absolute;
    width: 40px;
    min-height: 40px;

    bottom: 8px;
    right: 19px;

    .map-icon {
      min-width: 24px;
      min-height: 24px;
    }
  }
`

function useWatchAddress(onAddressUpdated: (address: Address | undefined) => void) {
  React.useEffect(() => {
    const promise = Geolocation.watchPosition({ enableHighAccuracy: true }, async (position) => {
      if (!position) {
        return
      }

      const geolocationAddress = await getAddressFromGeolocation(position)

      onAddressUpdated(geolocationAddress)
    })

    return () => {
      promise.then((watchId) => {
        Geolocation.clearWatch({ id: watchId })
      })
    }
  }, [onAddressUpdated])
}

export function HomeMapWithLocation() {
  const { deckManagerRef, setGoogleMap, selectedCarrier, focusMarker, googleMap } = useCoverageMapLogic()
  const [networkType, currentAddress, userCarrierId, addressesFromStore, setCurrentAddress] = useGeneralStore(
    (state) => [state.networkType, state.currentAddress, state.userCarrierId, state.addresses, state.setCurrentAddress],
    shallow
  )

  const userCarrier = UseCarrier.useCarrier(userCarrierId)

  const currentAddressArr = React.useMemo(() => {
    if (!currentAddress) {
      return []
    }
    return [{ address: currentAddress, active: true }]
  }, [currentAddress])

  const addresses = React.useMemo(() => {
    return addressesFromStore.filter((address) => address.placeId !== currentAddress?.placeId)
  }, [addressesFromStore, currentAddress])

  useMarkers({
    icon: '/assets/images/home/current_location.png',
    iconActive: '/assets/images/home/current_location.png',
    map: googleMap,
    onClick: focusMarker,
    locations: currentAddressArr
  })

  React.useEffect(() => {
    if (currentAddress) {
      focusMarker(currentAddress)
    }
  }, [currentAddress, focusMarker])

  useWatchAddress(setCurrentAddress)

  return (
    <article className={cx(HomeMapWithLocationStyles, HideDesktopStyles, IgnoreHomePadding, HomeMapBaseHeight)}>
      <IonRouterLink routerLink={MainNavigationPaths.map}>
        <InnerCoverageMap
          googleMap={googleMap}
          addresses={addresses}
          deckManagerRef={deckManagerRef}
          selectedCarrier={selectedCarrier}
          onClickMarker={focusMarker}
          networkType={networkType}
          setGoogleMap={setGoogleMap}
          zoom={14}
          gestureHandling='cooperative'
        />

        <div className='indicator-5g'>
          <div>{userCarrier.data?.name ?? '...'}</div>
          <div className='separator'></div>
          <div>5G</div>
        </div>

        <IonButton onClick={preventDefault} routerLink={MainNavigationPaths.map} className='map-link'>
          <img className='map-icon' src='/assets/icons/footer/map.svg' alt='map icon' />
        </IonButton>
      </IonRouterLink>
    </article>
  )
}
