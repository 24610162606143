import { css } from '@emotion/css'

const EmptyListStateStyles = css`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 var(--empty-list-padding, --plans-tab-horizontal-padding);

  h3 {
    font-weight: 900;
  }

  p {
    font-size: 18px;
  }
`

export function EmptyListState() {
  return (
    <div className={EmptyListStateStyles}>
      <img src='/assets/images/barry/barry_thinking.svg' alt='Barry thinking' />
      <h3>No matches found for your criteria</h3>
      <p>Try changing the number of lines or exploring other search terms.</p>
    </div>
  )
}
