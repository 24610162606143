import * as React from 'react'
import { css } from '@emotion/css'
import { useLocationPermission } from './useLocationPermissions'
import { LocationModal } from './LocationPermissionModal'

export const BarryWrapperStyles = css`
  display: flex;
  justify-content: center;
`

export function MapPermissionsPopup(props: {
  bgModalBody?: React.ReactNode | React.ReactNode[]
  locationModalBody?: React.ReactNode | React.ReactNode[]
  onClose: () => void
}) {
  const { hasLocation } = useLocationPermission()

  const permissionState = getPermissionState()
  function getPermissionState() {
    if (hasLocation.isLoading) {
      return 'loading'
    }

    if (hasLocation.data?.isGranted) {
      return 'granted'
    }

    return 'show-location'
  }

  if (permissionState === 'granted' || permissionState === 'loading') {
    return null
  }

  return <LocationModal body={props.locationModalBody} onClose={props.onClose} />
}
