import { css, cx } from '@emotion/css'
import { Plan } from '../../../services/planService/Plan'
import { MOBILE_BREAKPOINT, MOBILE_BREAKPOINT_MAX } from '../../../utils/hooks/useWindowSize'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import React from 'react'
import { PlanResultCardSkeleton } from '../../../components/PlanResult/PlanResultCardSkeleton'
import { throttle } from 'lodash'
import { ComparePlanResultCard } from '../../../components/BestPlans/ComparePlanResultCard'
import { useBoundStore } from '../../../store'
import shallow from 'zustand/shallow'
import { UseCarrier, useSupportedCarriers } from '../../../services/Carrier.service'
import { EmptyListState } from './EmptyListState'
import { PlansHeaderStates } from '../../../components/Header/PlansHeader/PlansHeader'
import { WarningBanner } from '../../../components/ListBanner'
import { GeoJsonRecord } from '../../../components/CoverageMap/Geodata.service'
import { Address } from '../../../store/address'

const GRID_BREAKPOINT = 1000

const TopSpaceStyles = css`
  @media screen and (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
    &.state-expanded {
      margin-top: var(--plans-header-expanded-height);
    }
    &.state-collapsed {
      margin-top: var(--plans-header-collapsed-height);
    }
    &.state-only-tabs {
      margin-top: var(--plans-header-only-tabs-height);
    }
  }

  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    &.state-expanded {
      margin-top: var(--plans-header-expanded-height);
    }
    &.state-collapsed {
      margin-top: var(--plans-header-collapsed-height);
    }
    &.state-only-tabs {
      margin-top: var(--plans-header-only-tabs-height);
    }
  }
`

const PlansListStyles = css`
  padding: 0 var(--plans-tab-horizontal-padding);
  .last-card-item {
    padding-bottom: 100px !important;
  }
  max-height: 100%;
  touch-action: pan-y;

  .cards-grid {
    margin: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;

    padding-top: 16px;
    padding-bottom: 16px;

    @media screen and (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
      padding-left: 16px;
      padding-right: 16px;
    }

    --min-width: 300px;

    & > * {
      width: 100%;
      flex-basis: var(--min-width);
      flex-grow: 1;
      max-width: 800px;
    }
    > * > * {
      height: 100%;
      min-width: var(--min-width);

      @media screen and (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
      }
    }

    &.lastCardItem {
      padding-bottom: 80px;
    }
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
    &.state-expanded {
      height: calc(100% - var(--plans-header-expanded-height) + 6px) !important;
    }
    &.state-collapsed {
      height: calc(100% - var(--plans-header-collapsed-height) + 6px) !important;
    }
    &.state-only-tabs {
      height: calc(100% - var(--plans-header-only-tabs-height) + 6px) !important;
    }
  }

  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    > div {
      position: relative !important;
      top: unset !important;
      padding-bottom: 20px;
    }
    &.state-only-tabs {
      height: calc(100% - var(--plans-header-only-tabs-height)) !important;
    }
  }
`

export interface PlansListTabProps {
  plans: Plan[]
  className?: string
  headerState?: PlansHeaderStates
  isLoading?: boolean
  onMove?: (direction: 'up' | 'down') => void
  onReachTop?: () => void
  onPlanClick: (plan: Plan, index: number) => void
  initialScrollIndex?: number
  compareView?: boolean
  afterCompare?: (planResult: Plan) => React.ReactNode
  virtuosoRef?: React.RefObject<VirtuosoHandle>
}

function shouldShowNoDataBanner(bestCarriers: GeoJsonRecord[] | undefined, addresses: Address[]): boolean {
  if (
    addresses.length &&
    bestCarriers &&
    (!bestCarriers.length || bestCarriers.every((record) => record.properties.network_type_id === 0))
  ) {
    return true
  }
  return false
}

export function PlansListTab(props: PlansListTabProps) {
  const { plans } = props
  const lastTouchPosition = React.useRef(0)
  const isTwoColumns = window.innerWidth > GRID_BREAKPOINT

  const [compareSelection, toggleComparison, addresses] = useBoundStore(
    (state) => [state.compareSelection, state.toggleComparison, state.addresses],
    shallow
  )

  const bestCarriers = UseCarrier.useBestCarriers()

  const noDataFound = shouldShowNoDataBanner(bestCarriers.data, addresses)
  const beginningBanner = React.useMemo(() => {
    if (noDataFound) {
      return {
        exists: true,
        type: 'warning',
        title: 'No coverage data for your location(s)',
        message: "Try adding different locations or running speed tests with Goji's Network Meter."
      }
    }
    return { exists: false }
  }, [noDataFound])

  const { isPlanCarrierSupported } = useSupportedCarriers()

  const handleTouchMove = throttle((e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches[0].clientY > lastTouchPosition.current + 50) {
      lastTouchPosition.current = e.touches[0].clientY
      props.onMove?.('up')
    } else if (e.touches[0].clientY < lastTouchPosition.current - 50) {
      lastTouchPosition.current = e.touches[0].clientY
      props.onMove?.('down')
    }
  }, 300)

  const innerPlanComponent = (index: number) => {
    return (
      <ComparePlanResultCard
        key={plans[index].planId}
        plan={plans[index]}
        isComparing={compareSelection.has(plans[index].planId)}
        onCheckboxSelected={toggleComparison}
        disabled={compareSelection.size === 4 && !compareSelection.has(plans[index].planId)}
        compareView={props.compareView}
        onPlanClick={() => {
          props.onPlanClick(plans[index], index)
        }}
        afterCompare={props.afterCompare?.(plans[index])}
        isFuzzy={false}
        isCarrierUnsupported={!isPlanCarrierSupported(plans[index])}
        alwaysExpanded
      />
    )
  }

  return (
    <>
      <div className={cx(TopSpaceStyles, 'top-space', props.headerState)}></div>
      {props.isLoading ? (
        <Virtuoso
          className={cx(PlansListStyles, 'list-virtuoso', props.headerState, props.className)}
          totalCount={5}
          initialTopMostItemIndex={0}
          itemContent={() => <PlanResultCardSkeleton />}
        />
      ) : (
        <Virtuoso
          initialTopMostItemIndex={
            isTwoColumns ? Math.floor((props.initialScrollIndex ?? 0) / 2) : (props.initialScrollIndex ?? 0) / 2
          }
          atTopStateChange={(isAtTop) => {
            if (isAtTop) {
              props.onReachTop?.()
            }
          }}
          className={cx(PlansListStyles, 'list-virtuoso', props.headerState, props.className)}
          totalCount={Math.ceil(plans.length / 2)}
          components={{
            EmptyPlaceholder: () => <EmptyListState />
          }}
          onTouchStart={(e) => {
            lastTouchPosition.current = e.touches[0].clientY
          }}
          onTouchMove={handleTouchMove}
          onWheel={(e) => {
            if (e.deltaY > 0) {
              props.onMove?.('down')
            } else if (e.deltaY < 0) {
              props.onMove?.('up')
            }
          }}
          ref={props.virtuosoRef}
          itemContent={(index) => {
            return (
              <>
                {index === 0 && beginningBanner.exists ? (
                  <WarningBanner title={beginningBanner.title || ''} content={beginningBanner.message || ''} />
                ) : null}
                <div className={cx('cards-grid', { lastCardItem: index === Math.ceil(plans.length / 2) - 1 })}>
                  {innerPlanComponent(index * 2)}
                  {plans[index * 2 + 1] ? innerPlanComponent(index * 2 + 1) : <div />}
                </div>
              </>
            )
          }}
        />
      )}
    </>
  )
}
