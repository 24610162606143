import { css } from '@emotion/css'
import { IonButton, IonSpinner } from '@ionic/react'
import { UseCarrier } from '../../../services/Carrier.service'
import { useBoundStore } from '../../../store'
import { useSetUserLocation } from '../../network-quality/hooks/useSetUserLocation'
import HomeMapBase from './HomeMapBase'

const EnableButtonStyles = css`
  --background: var(--ion-background-color);
  --border-radius: 24px;
  color: var(--ion-text-color);

  margin-top: 12px;

  .btn-text {
    margin-left: 8px;
  }
`

const SpinnerStyles = css`
  --color: var(--ion-text-color);
  height: 16px;
`

export function HomeMapMissingLocation() {
  const userCarrierId = useBoundStore((state) => state.userCarrierId)
  const carrier = UseCarrier.useCarrier(userCarrierId)
  const { setUserLocation, loadingLocation } = useSetUserLocation()

  return (
    <HomeMapBase
      title='Last step!'
      subtitle={`We need location permissions  to display ${carrier.data?.name}'s coverage here`}
      overlayOpacity='0.56'
      overlayColor='var(--goji-primary-black)'
      backgroundImage='/assets/images/home/home-map.webp'
      sideImage='/assets/images/barry/barry_sitting2.svg'
      sideImageAlt='goji barry sitting with a phone on his hand'
    >
      <IonButton onClick={setUserLocation} className={EnableButtonStyles}>
        {loadingLocation ? (
          <IonSpinner className={SpinnerStyles} />
        ) : (
          <>
            <img src='/assets/icons/navigate.svg' alt='navigate icon' />
            <span className='btn-text'>Enable location</span>
          </>
        )}
      </IonButton>
    </HomeMapBase>
  )
}
