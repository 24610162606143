import { css, cx } from '@emotion/css'
import React from 'react'
import { SavedPlans } from './SavedPlans'
import { Top3Plans } from './Top3Plans'

type Tab = 'top3' | 'saved'

function TabDisplayed(props: { tab: Tab }) {
  switch (props.tab) {
    case 'top3':
      return <Top3Plans />
    case 'saved':
      return <SavedPlans />
  }
}

const HomePlanTabStyles = css`
  .tab-heading {
    margin-bottom: 16px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0;

    .tab-li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      text-align: center;
      flex-grow: 1;
      list-style-type: none;

      --color: var(--ion-color-medium);
      border-bottom: 1.5px solid var(--ion-color-light);
      &.active {
        border-bottom: 2.5px solid black;
        --color: var(--ion-text-color);
      }
    }

    .tab-button {
      width: 100%;
      height: 100%;
      font-size: 18px;
      font-weight: 900;
      background: none;
      color: var(--color);
    }
  }
`

export function HomePlanTabs() {
  const [tab, setTab] = React.useState<Tab>('top3')

  return (
    <section className={HomePlanTabStyles}>
      <ul className='tab-heading'>
        <li className={cx('tab-li', { active: tab === 'top3' })}>
          <button className='tab-button' onClick={() => setTab('top3')}>
            Top 3 for you
          </button>
        </li>
        <li className={cx('tab-li', { active: tab === 'saved' })}>
          <button className='tab-button' onClick={() => setTab('saved')}>
            Recently Saved
          </button>
        </li>
      </ul>
      <TabDisplayed tab={tab} />
    </section>
  )
}
