import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

export const hasCurrentLocation = 'hasCurrentLocation'

export const initAmplitude = () => {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY ?? '', {
    defaultTracking: process.env.NODE_ENV === 'production',
    appVersion: process.env.REACT_APP_GOJI_VERSION ?? 'dev'
  })
  if (process.env.NODE_ENV === 'production') {
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 0.05
    })
    amplitude.add(sessionReplayTracking)
  }
}

export const setAmplitudeUserId = (userId: string) => {
  amplitude.setUserId(userId)
}

export const trackAmplitudeEvent = (eventType: string, eventProperties?: Record<string, unknown>) => {
  if (process.env.NODE_ENV === 'production') amplitude.track(eventType, eventProperties)
}

export const trackUserPropertyFlag = (key: string, value: boolean) => {
  if (process.env.NODE_ENV === 'production') {
    const new_prop = new amplitude.Identify()
    new_prop.set(key, value)
    amplitude.identify(new_prop)
  }
}
