import { useQuery } from 'react-query'
import API from '../../../api'
import { Deal } from '../deal.service'

export function useDeals() {
  return useQuery({
    queryKey: ['deals'],
    queryFn: async () => {
      const data = await API.get<Deal[]>('/deals')
      return data
    }
  })
}
