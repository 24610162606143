import * as React from 'react'
import { IonButton, IonCol, IonFooter, IonRow, IonText, IonToolbar } from '@ionic/react'

import './results.css'
import { useBoundStore } from '../../store'
import shallow from 'zustand/shallow'
import { RoutePaths } from '../../paths'

interface CompareFooterProps {
  setCompareView: (val: boolean) => void
}
export const CompareFooter: React.FC<CompareFooterProps> = ({ setCompareView }) => {
  const [compareSelection, clearComparisons] = useBoundStore(
    (state) => [state.compareSelection, state.clearComparisons],
    shallow
  )
  const closeCompare = () => {
    clearComparisons()
    setCompareView(false)
  }

  return (
    <IonFooter className='custom-width-limiter compare-footer'>
      <IonToolbar>
        <IonRow className='ion-justify-content-center ion-align-items-center'>
          <IonCol>
            <IonText color='medium'>Select up to 4 plans to compare</IonText>
          </IonCol>
          <IonCol size='auto'>
            <IonText color='dark' onClick={closeCompare} className='close-button'>
              Close
            </IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonButton
              disabled={compareSelection.size < 2}
              routerLink={RoutePaths.compare + '/' + Array.from(compareSelection).join(',')}
              size='large'
              shape='round'
              expand='block'
            >
              Compare Plans {compareSelection.size ? `(${compareSelection.size})` : null}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonToolbar>
    </IonFooter>
  )
}

export const API_KEY = process.env.REACT_APP_MAPS_API_KEY
