import { css, cx } from '@emotion/css'
import { IonFabButton, IonIcon } from '@ionic/react'
import { arrowBack, arrowForward } from 'ionicons/icons'
import { MOBILE_BREAKPOINT } from '../../../utils/hooks/useWindowSize'

const ArrowControlsStyles = css`
  z-index: 2;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  display: none;
  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    justify-content: space-between;
  }

  .fab-show-arrow {
    pointer-events: all;
    &.hidden {
      visibility: hidden;
    }

    &.left {
      left: -24px;
    }

    &.right {
      right: -24px;
    }

    ion-fab-button {
      width: 32px;
      height: 32px;
    }
  }
`

interface ArrowControlsProps {
  onForward: () => void
  onBackward: () => void
  swiperPosition?: number
  className?: string
}
export function ArrowControls(props: ArrowControlsProps) {
  return (
    <div className={cx(ArrowControlsStyles, props.className)}>
      <div className='fab-show-arrow left'>
        <IonFabButton disabled={props.swiperPosition === 0} title='Show previous' onClick={props.onBackward}>
          <IonIcon icon={arrowBack} />
        </IonFabButton>
      </div>
      <div className='fab-show-arrow right'>
        <IonFabButton disabled={props.swiperPosition === 1} title='Show next' onClick={props.onForward}>
          <IonIcon icon={arrowForward} />
        </IonFabButton>
      </div>
    </div>
  )
}
