import { css } from '@emotion/css'
import { SortingOption } from '../../../components/Header/PlansHeader/PlansHeaderChips'
import { LazyPlansCarousel } from '../../../components/PlanResult/LazyPlansCarousel'
import { PlanResultCardSkeleton } from '../../../components/PlanResult/PlanResultCardSkeleton'
import { UsePlan } from '../../../services/planService/Plan.service'
import { flatMapPages } from '../../../utils/flat-map-pages'

const TopPlansHeightStyles = css`
  height: 564px;
  margin-bottom: 24px;
`

export function Top3Plans() {
  const planResultsQuery = UsePlan.useBestPlans({ sortBy: SortingOption.Featured })
  const planResults = planResultsQuery.isLoading ? [] : flatMapPages(planResultsQuery.data)
  const top3Plans = planResults.slice(0, 3)

  return (
    <section className={TopPlansHeightStyles}>
      <main>
        {!planResultsQuery.isFetched ? (
          <>
            <PlanResultCardSkeleton />
            <PlanResultCardSkeleton />
            <PlanResultCardSkeleton />
          </>
        ) : (
          <LazyPlansCarousel plans={top3Plans} />
        )}
      </main>
    </section>
  )
}
