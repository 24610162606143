import { css } from '@emotion/css'
import { IonButton } from '@ionic/react'

export const ViewMoreLinkStyles = css`
  --border-color: var(--goji-primary-black);
  --border-width: 1px;
  --border-style: solid;
  --border-radius: 32px;
  --background: var(--ion-background-color);
  --color: var(--ion-text-color);

  &:focus {
    --border-color: var(--ion-color-primary);
    --border-width: 2px;
  }

  font-weight: 500;
  margin-top: 40px;
  width: 100%;
  max-width: 450px;

  height: 46px;
`
export function ViewMoreLink(props: { routerLink: string; children: React.ReactNode | React.ReactNode[] }) {
  return (
    <IonButton routerLink={props.routerLink} className={ViewMoreLinkStyles}>
      {props.children}
    </IonButton>
  )
}
