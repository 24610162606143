import { css } from '@emotion/css'
import React from 'react'
import shallow from 'zustand/shallow'
import { SplitCarrierListScaffolding } from '../../../components/CoverageMap/TopBar/SplitCarriersList'
import { PopoverSelect, SelectItem } from '../../../components/PopoverSelect/PopoverSelect'
import { UseCarrier } from '../../../services/Carrier.service'
import { useBoundStore } from '../../../store'
import { initCurrentAddress } from '../../../store/initializers/initCurrentAddress'
import HomeMapBase from './HomeMapBase'
import { HomeMapMissingLocation } from './HomeMapMissingLocation'
import { HomeMapWithLocation } from './HomeMapWithLocation'

const PopoverSelectWrapperStyles = css`
  width: 214px;
  margin-top: 12px;
`

function HomeMapBaseCarrierSelector() {
  const carriers = UseCarrier.useCarriersSplit(UseCarrier.useAvailableInMyRegion(UseCarrier.useAllCarriers()))
  const defaultCarriers = carriers.data?.defaultCarriers ?? []
  const otherCarriers = carriers.data?.otherCarriers ?? []

  const [userCarrierId, setCarrierId] = useBoundStore((state) => [state.userCarrierId, state.setCarrierId], shallow)
  const selectedCarrier = UseCarrier.useCarrier(userCarrierId)

  return (
    <HomeMapBase
      sideImage='/assets/images/barry/barry_map.svg'
      sideImageAlt='goji barry holding a map'
      title='Real time coverage check'
      subtitle='See what your coverage is now'
      overlayOpacity='0.8'
      overlayColor='#187103'
      backgroundImage='/assets/images/home/home-map.webp'
    >
      <div className={PopoverSelectWrapperStyles}>
        <PopoverSelect
          displayValue={selectedCarrier.data?.name}
          value={userCarrierId}
          onSelect={setCarrierId}
          placeholder='Who’s your carrier?'
        >
          <SplitCarrierListScaffolding
            majorCarriersSlot={defaultCarriers.map((carrier) => {
              return (
                <SelectItem value={carrier.id} key={carrier.id} lines='none'>
                  {carrier.name}
                </SelectItem>
              )
            })}
            otherCarriersSlot={otherCarriers.map((carrier) => {
              return (
                <SelectItem value={carrier.id} key={carrier.id} lines='none'>
                  {carrier.name}
                </SelectItem>
              )
            })}
          />
        </PopoverSelect>
      </div>
    </HomeMapBase>
  )
}

enum HomeMapSwitchState {
  INITIAL,
  MISSING_LOCATION,
  MAP_WITH_LOCATION
}

function useHomeMapSwitchState(): HomeMapSwitchState {
  const [userCarrierId, currentAddress] = useBoundStore((state) => [state.userCarrierId, state.currentAddress], shallow)

  React.useEffect(() => {
    initCurrentAddress()
  }, [])

  if (!userCarrierId) {
    return HomeMapSwitchState.INITIAL
  }

  if (!currentAddress) {
    return HomeMapSwitchState.MISSING_LOCATION
  }

  return HomeMapSwitchState.MAP_WITH_LOCATION
}

function HomeMapSwitch() {
  const state = useHomeMapSwitchState()

  switch (state) {
    case HomeMapSwitchState.INITIAL:
      return <HomeMapBaseCarrierSelector />
    case HomeMapSwitchState.MISSING_LOCATION:
      return <HomeMapMissingLocation />
    case HomeMapSwitchState.MAP_WITH_LOCATION:
      return <HomeMapWithLocation />
  }
}

export default HomeMapSwitch
