import { RecentSearchService } from '../../services/RecentSearch.service'
import { useBoundStore } from '../../store'

export const useRecentPlanSearch = () => {
  const setRecentPlanSearch = useBoundStore((state) => state.setSearchedPlansHistory)

  const handleSearch = async (search: string) => {
    const newSearchHistory = await RecentSearchService.addRecentPlanSearch(search)
    if (!newSearchHistory) return
    setRecentPlanSearch(newSearchHistory)
  }

  return { handleSearch }
}
