import { css, cx } from '@emotion/css'
import { IonButton, IonSpinner } from '@ionic/react'
import React from 'react'
import { SwiperSlide } from 'swiper/react'
import { MainNavigationPaths } from '../../../../paths'
import { Plan } from '../../../../services/planService/Plan'
import { MOBILE_BREAKPOINT } from '../../../../utils/hooks/useWindowSize'
import { FlexCenter, FullHeight } from '../../../../utils/styles/flex-utils'
import { DealCard } from '../../../deals/DealCard'
import { useDeals } from '../../../deals/hooks/useDeals'
import { HomeCaroulsel } from '../../HomeCaroulsel/HomeCaroulsel'
import { HomeTitleStyles } from '../../styles/title-styles'
import { ViewMoreLink } from '../components/VireMoreLink'
import { MAX_CAROUSEL_LENGTH } from '../constants/max_carousel_length'

type PromotionTab = {
  label: string
  dealType: string
}

const FOR_YOU = 'for_you'
const staticTabs: PromotionTab[] = [
  {
    label: 'Phone deals',
    dealType: 'Phone'
  },
  {
    label: 'Discounts',
    dealType: 'Discount'
  },
  {
    label: 'Referrals',
    dealType: 'Referral'
  },
  {
    label: 'Other',
    dealType: 'Other'
  }
]

const PromotionTabComponentStyles = css`
  display: inline;
  border: 1px solid var(--ion-color-light);
  border-radius: 8px;

  .tab-button {
    background: none;
    color: var(--ion-text-color);
    padding: 8px 12px;
  }

  &.active {
    border-color: transparent;
    background-color: var(--ion-color-primary);

    .tab-button {
      width: 100%;
      height: 100%;
      color: var(--ion-color-primary-contrast);
      font-weight: 700;
    }
  }
`
function PromotionTabComponent(props: { tab: PromotionTab; onClick?: () => void; active?: boolean }) {
  return (
    <li className={cx(PromotionTabComponentStyles, { active: props.active })}>
      <button onClick={props.onClick} className='tab-button'>
        {props.tab.label}
      </button>
    </li>
  )
}

const PromotionTabListStyles = css`
  margin-top: 16px;
  display: inline-flex;
  gap: 8px;
  white-space: nowrap;
  padding: 0;
  padding-bottom: 8px;
  list-style-type: none;
  max-width: 100%;
  overflow-x: auto;
`

function getTabs(favoriteDeals: unknown[]) {
  if (favoriteDeals.length === 0) {
    return staticTabs
  }
  return [
    {
      label: 'For you',
      dealType: FOR_YOU
    },
    ...staticTabs
  ]
}

const dealCardHeight = 182
const arrowSize = 32
const arrowPadding = 24

const TrendingPromotionsStyles = css`
  --deal-card-height: ${dealCardHeight}px;
  .deal-card {
    max-width: 382px;
    height: 182px;

    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
    }
  }

  .deals-list {
    height: 225px;
    margin-top: 8px;

    @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
      margin-top: 16px;
    }

    .arrow-controls {
      left: -${arrowSize + arrowPadding}px;
      width: calc(100% + ${(arrowSize + arrowPadding) * 2}px);
      top: ${dealCardHeight / 2}px;
    }
  }
`

type PlanWithDeals = Pick<Plan, 'deals'>

const MAX_DEALS_PER_PLAN = 3
export function getDealIds(favoritePlans: PlanWithDeals[]) {
  const ids: number[] = []
  for (const plan of favoritePlans) {
    for (let i = 0; i < Math.min(MAX_DEALS_PER_PLAN, plan.deals.length); i++) {
      ids.push(plan.deals[i].dealId)
    }
  }

  return ids
}

type TrendingPromotionsProps = {
  favoritePlans: PlanWithDeals[]
}
export function TrendingPromotions(props: TrendingPromotionsProps) {
  const deals = useDeals()

  const dealIds = getDealIds(props.favoritePlans)
  const favoriteDeals = (deals.data ?? []).filter((deal) => dealIds.includes(deal.dealId))

  const [selectedTab, setSelectedTab] = React.useState(0)
  const tabs = getTabs(favoriteDeals)

  function getDealsList() {
    if (tabs[selectedTab].dealType === FOR_YOU) {
      return favoriteDeals.slice(0, MAX_CAROUSEL_LENGTH)
    }
    return (deals.data ?? [])
      .filter((deal) => deal.dealType === tabs[selectedTab].dealType)
      .slice(0, MAX_CAROUSEL_LENGTH)
  }

  const dealsList = getDealsList()

  const dealsListRef = React.useRef<HTMLDivElement>(null)

  return (
    <section className={TrendingPromotionsStyles}>
      <h2 className={HomeTitleStyles}>Trending Promotions</h2>

      <ul className={PromotionTabListStyles}>
        {tabs.map((tab, idx) => {
          return (
            <PromotionTabComponent
              active={idx === selectedTab}
              onClick={() => setSelectedTab(idx)}
              tab={tab}
              key={tab.label}
            />
          )
        })}
      </ul>

      <div ref={dealsListRef} className='deals-list'>
        {deals.error ? (
          <div>
            Error while fetching promotions. <IonButton onClick={() => deals.refetch()}>Try again</IonButton>
          </div>
        ) : null}
        {deals.isLoading ? (
          <div className={cx(FlexCenter, FullHeight)}>
            <IonSpinner />
          </div>
        ) : (
          <HomeCaroulsel className='home-carousel' arrowControlsStyles='arrow-controls'>
            {dealsList.map((deal) => {
              return (
                <SwiperSlide key={deal.dealId}>
                  <DealCard className='deal-card' onClick={() => {}} deal={deal} />
                </SwiperSlide>
              )
            })}
          </HomeCaroulsel>
        )}
      </div>

      <div className={FlexCenter}>
        <ViewMoreLink routerLink={MainNavigationPaths.deals}>View more promotions</ViewMoreLink>
      </div>
    </section>
  )
}
