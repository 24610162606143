import { css, cx } from '@emotion/css'
import { IonHeader, IonSearchbar, IonToolbar } from '@ionic/react'
import * as React from 'react'
import 'swiper/css'
import { FreeMode } from 'swiper'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import { PlansHeaderSearchDrawer } from './PlansHeaderSearchDrawer'
import { PlansHeaderChips, SortingOption, SortingOptions } from './PlansHeaderChips'
import { PlansHeaderSearchDrawerDesktop } from './PlansHeaderSearchDrawerDesktop'
import { MOBILE_BREAKPOINT, useIsMobileSize } from '../../../utils/hooks/useWindowSize'
import { HideDesktopStyles, HideMobileStyles } from '../../../utils/styles/ViewportUtils.styles'
import { useRecentPlanSearch } from '../../../utils/hooks/useRecentPlanSearch'
import { useBoundStore } from '../../../store'
import { useFlag } from '../../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../../utils/FeatureFlags'
import { TitlesWrapperStyles } from '../../../pages/profile/SavedPlans.styles'
import { PlanHeaderSearchbarStyles, PlanSearchbarStyles } from './styles/toolbar.styles'

export const plansHeaderStyles = css`
  --background: var(--goji-primary-lime);
  --border-width: 0 !important;
  --padding-start: 0;
  --padding-end: 0;

  box-shadow: 0px 4px 4px 0px #0000000d;

  color: var(--ion-color-dark);
  transition: height 0.3s;
  &.state-collapsed {
    height: var(--plans-header-collapsed-height);
  }
  &.state-only-tabs {
    height: var(--plans-header-only-tabs-height);
  }

  .titles-wrapper {
    width: 100%;
    padding: 0 45px;
    &.state-collapsed {
      transform: translateY(-100%);
      opacity: 0;
    }
    margin-top: 16px;
    margin-bottom: 14px;
    transition: font-size 0.3s, opacity 0.3s, font-weight 0.3s, transform 0.3s, opacity 0.3s;
    align-items: end;

    .swiper-wrapper > .swiper-slide {
      width: fit-content;
      height: 26px;
      display: flex;
    }

    &.desktop {
      display: flex;
      height: 26px;
    }
  }
`

const headerTitleStyles = css`
  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 25px;
  }
  text-wrap: nowrap;
  display: flex;
  align-items: flex-end;
  transition: font-size 0.3s, opacity 0.3s, transform 0.3s;
  cursor: pointer;
  overflow: visible;
`

export enum PlansHeaderStates {
  EXPANDED = 'state-expanded',
  COLLAPSED = 'state-collapsed',
  ONLY_TABS = 'state-only-tabs'
}

interface PlansHeaderTitleProps {
  title: string
  selected?: boolean
  onClick?: () => void
}

function PlansHeaderTitle(props: PlansHeaderTitleProps) {
  return (
    <div
      className={headerTitleStyles}
      style={{
        fontWeight: props.selected ? '900' : '500',
        fontSize: props.selected ? '22.4px' : '16px',
        opacity: props.selected ? 1 : 0.6
      }}
      onClick={props.onClick}
    >
      <span>{props.title}</span>
    </div>
  )
}

const MAX_SEARCH_LENGTH = 40

interface PlansHeaderProps {
  className?: string
  tabs: string[]
  onTabChange?: (tab: number) => void
  tab: number
  expandedState: PlansHeaderStates
  isOnTop?: boolean
  search?: string
  nLinesFilter: number
  onSearch?: (search: string) => void
  onFilter?: (nLines: number) => void
  sortByPerTab: { [key: number]: SortingOption }
  onSort?: (sortBy: SortingOption, tab: number) => void
  children?: React.ReactNode
  headerChipsClassName?: string
}

export const PlansHeader = React.forwardRef(function PlansHeader(
  props: PlansHeaderProps,
  swiperRef: React.ForwardedRef<SwiperRef>
) {
  const chatUiFlag = useFlag(FeatureFlag.CHAT_UI)
  const isMobile = useIsMobileSize()
  const state = props.expandedState || PlansHeaderStates.EXPANDED
  const discover = ['AT&T', 'T-Mobile', 'Verizon', 'U.S. Cellular', 'Mint Mobile', 'Unlimited']

  const [isSearchDrawerOpen, setIsSearchDrawerOpen] = React.useState(false)

  const recentPlans = useBoundStore((state) => state.searchedPlansHistory)
  const { handleSearch } = useRecentPlanSearch()

  const handleSearchWrapper = async (search: string) => {
    props.onSearch?.(search)
    setIsSearchDrawerOpen(false)
    await handleSearch(search)
  }

  return (
    <>
      <IonHeader>
        <IonToolbar className={cx(plansHeaderStyles, props.className, state)}>
          {props.children}
          <Swiper
            className={cx(HideDesktopStyles, 'titles-wrapper', state)}
            ref={swiperRef}
            slidesPerView={'auto'}
            spaceBetween={50}
            centeredSlides
            centeredSlidesBounds
            freeMode={true}
            modules={[FreeMode]}
            onSlideChange={() => setIsSearchDrawerOpen(false)}
          >
            {props.tabs.map((child, index) => (
              <SwiperSlide key={child}>
                <PlansHeaderTitle
                  title={child}
                  selected={props.tab === index}
                  onClick={() => props.onTabChange?.(index)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <div
            className={cx(HideMobileStyles, TitlesWrapperStyles, 'titles-wrapper', 'desktop', state)}
            id='desktop-titles'
          >
            {props.tabs.map((child, index) => (
              <PlansHeaderTitle
                key={child}
                title={child}
                selected={props.tab === index}
                onClick={() => props.onTabChange?.(index)}
              />
            ))}
          </div>
          <IonSearchbar
            className={cx(PlanSearchbarStyles, PlanHeaderSearchbarStyles, state)}
            placeholder='Search'
            maxlength={MAX_SEARCH_LENGTH}
            value={props.search}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                handleSearchWrapper(e.currentTarget.value!)
                ;(e.nativeEvent.target as HTMLInputElement).blur()
              }
            }}
            onIonFocus={() => {
              setIsSearchDrawerOpen(true)
            }}
            onIonClear={() => {
              handleSearchWrapper('')
            }}
          />
        </IonToolbar>
      </IonHeader>
      <PlansHeaderChips
        className={props.headerChipsClassName}
        collapsed={state !== PlansHeaderStates.EXPANDED}
        nLinesFilter={props.nLinesFilter}
        onNLinesFilterChange={(nLines) => {
          props.onFilter?.(nLines)
        }}
        sortBy={props.sortByPerTab[props.tab] ?? SortingOptions[0].value}
        onSortByChange={(sortBy) => {
          props.onSort?.(sortBy, props.tab)
        }}
        showFiltersChip={props.tab === 0}
        editPreferences={chatUiFlag ? false : props.tab === 1}
        isOnTop={props.isOnTop}
      />
      {isMobile && (
        <PlansHeaderSearchDrawer
          initValue={props.search!}
          isOpen={isSearchDrawerOpen}
          onSearch={(search) => {
            handleSearchWrapper(search)
          }}
          clearSearch={() => {
            handleSearchWrapper('')
          }}
          onDismiss={() => setIsSearchDrawerOpen(false)}
          sections={[
            { title: 'History', chips: recentPlans || [] },
            { title: 'Discover', chips: discover }
          ]}
        />
      )}
      <PlansHeaderSearchDrawerDesktop
        isOpen={isSearchDrawerOpen}
        sections={[
          { title: 'History', chips: recentPlans || [] },
          { title: 'Discover', chips: discover }
        ]}
        onSearch={(search) => {
          handleSearchWrapper(search)
        }}
        onDismiss={() => setIsSearchDrawerOpen(false)}
        expandState={state}
      />
    </>
  )
})
