import { css } from '@emotion/css'
import { IonItem } from '@ionic/react'
import { UseCarrier } from '../../../services/Carrier.service'
import { NetworkType, NetworkTypeValue } from '../../../services/NetworkType.service'
import { Carrier } from '../../../store/carrierSelector'

const BestChipStyles = css`
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
  padding: 2px 8px;
  font-size: 14px;
  border-radius: 2px;
  margin-left: 8px;
  font-weight: 600;
`
const BestChip = ({ networkType }: { networkType: NetworkTypeValue }) => {
  return <div className={BestChipStyles}>Best {networkType === NetworkType['4G'] ? '4G' : '5G'}</div>
}
const ListStyles = css`
  margin-top: 4px;
  min-height: 250px;
  max-height: 50vh;
  @media (max-height: 750px) {
    max-height: 40vh;
  }

  .divider {
    border-top: 1px solid var(--ion-color-light);
    margin: 8px 0 16px 0;
  }
`
const ListTitleStyles = css`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 16px 0 8px 14px;
  background-color: var(--ion-card-background);
`

const carrierItem = (
  carrier: Carrier,
  bestCarrier: Carrier | undefined,
  selectedTechnology: NetworkTypeValue,
  onClick: (carrier: Carrier) => void
) => (
  <IonItem
    key={carrier.id}
    onClick={() => {
      onClick(carrier)
    }}
    button
    detail={false}
    lines='none'
  >
    {carrier.name}
    {carrier.id && carrier.id === bestCarrier?.id ? <BestChip networkType={selectedTechnology} /> : ''}
  </IonItem>
)

export function SplitCarrierListScaffolding(props: {
  majorCarriersSlot: React.ReactNode | React.ReactNode[]
  otherCarriersSlot?: React.ReactNode | React.ReactNode[]
}) {
  return (
    <div className={ListStyles}>
      <h5 className={ListTitleStyles}>Major Carriers</h5>
      {props.majorCarriersSlot}
      {props.otherCarriersSlot ? (
        <>
          <div className='divider' />
          <h5 className={ListTitleStyles}>Other Carriers</h5>
        </>
      ) : null}
      {props.otherCarriersSlot}
    </div>
  )
}

export const SplitCarrierList = ({
  carriers,
  onClick,
  selectedTechnology
}: {
  carriers: Carrier[]
  onClick: (carrier: Carrier) => void
  selectedTechnology: NetworkTypeValue
}) => {
  const bestCarrier = UseCarrier.useBestCarrierForTechnology({ technology: selectedTechnology }).data
  const { defaultCarriers, otherCarriers } = carriers.reduce<{ defaultCarriers: Carrier[]; otherCarriers: Carrier[] }>(
    (acc, carrier) => {
      carrier.has_data ? acc.defaultCarriers.push(carrier) : acc.otherCarriers.push(carrier)
      return acc
    },
    { defaultCarriers: [], otherCarriers: [] }
  )

  return (
    <SplitCarrierListScaffolding
      majorCarriersSlot={defaultCarriers.map((carrier) =>
        carrierItem(carrier, bestCarrier, selectedTechnology, onClick)
      )}
      otherCarriersSlot={
        otherCarriers.length
          ? otherCarriers.map((carrier) => carrierItem(carrier, bestCarrier, selectedTechnology, onClick))
          : undefined
      }
    />
  )
}
