import { IonButton } from '@ionic/react'
import { css, cx } from '@emotion/css'

const CompareToolbarStyles = css`
  position: relative;
  display: flex;
  justify-content: flex-end;
  z-index: 10;

  & > .compare-button-fixed {
    position: fixed;
    bottom: var(--bottom-fixed, 15px);
    margin-right: 78px;

    .compare-button {
      --border-radius: 30px;
      text-transform: uppercase;
      border-radius: 30px;
      height: 48px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: normal;
    }
  }

  & > .fab-go-up-fixed {
    position: fixed;
    bottom: var(--bottom-fixed, 15px);
    margin-right: 16px;

    ion-fab-button {
      width: 48px;
      height: 48px;
      img {
        object-fit: contain;
        object-position: top;
      }
    }
  }
`

export const CompareToolbar = (props: {
  toggleCompare: () => void
  className?: string
  showCompareButton: boolean
  children: React.ReactNode
}) => {
  return (
    <div className={cx(CompareToolbarStyles, props.className)}>
      <div className='compare-button-fixed'>
        {props.showCompareButton ? (
          <IonButton onClick={props.toggleCompare} className='compare-button ion-no-margin'>
            Compare
          </IonButton>
        ) : null}
      </div>

      <div className='fab-go-up-fixed'>{props.children}</div>
    </div>
  )
}
