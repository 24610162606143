import React from 'react'
import { getInitialPreferencesV3, PlanPreferencesV3 } from '../../store/standalone/filters-store/filter-plans'
import { useQueryParamsState } from '../../utils/hooks/useSyncStateWithSearchParam'

function getInitialState() {
  return {
    search: '',
    'filters-open': undefined,
    ...getInitialPreferencesV3()
  }
}

export function usePlansFilterState() {
  const [qsState, setQsState] = useQueryParamsState<
    { search: string; 'filters-open': 'true' | undefined } & PlanPreferencesV3
  >(getInitialState())

  const setSearch = React.useCallback(
    (search: string) => {
      setQsState({ search })
    },
    [setQsState]
  )

  return {
    search: qsState.search,
    setSearch,
    qsState,
    setQsState
  }
}
