import { css } from '@emotion/css'
import { MOBILE_BREAKPOINT } from '../../../utils/hooks/useWindowSize'

export const HomeTitleStyles = css`
  margin: 0;
  font-weight: 900;
  font-size: 22px;

  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 28px;
  }
`
