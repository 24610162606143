import React from 'react'
import { css, cx } from '@emotion/css'
import { peopleOutline, swapVerticalOutline } from 'ionicons/icons'
import ControlChip from './ControlChip'
import { EditIcon } from '../../CoverageMap/MobileMapAddressesHeader'
import { useHistory } from 'react-router'
import { MOBILE_BREAKPOINT, MOBILE_BREAKPOINT_MAX } from '../../../utils/hooks/useWindowSize'
import { MainNavigationPaths, RoutePaths } from '../../../paths'
import { RECOMMENDED_TAB } from '../../../pages/plans/tabs/PlanTab'
import { DropdownSelector } from './DropdownSelector'
import { applyGeneralStoreToEditStore } from '../../../store/editStoreManagement'
import { getFilterDiff } from '../../../pages/plan-filters/components/utils/filters-diff'
import { getInitialPreferencesV3 } from '../../../store/standalone/filters-store/filter-plans'
import useFiltersStore from '../../../store/standalone/filters-store/filters-store'
import { useFlag } from '../../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../../utils/FeatureFlags'
import { HideDesktopStyles } from '../../../utils/styles/ViewportUtils.styles'

const chipsStyles = css`
  position: absolute;
  z-index: 9;
  background-color: transparent;
  padding-bottom: 8px;
  width: 100%;
  display: flex;
  gap: 8px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
  scroll-behavior: smooth;

  > * {
    margin-right: 8px;
  }
  > *:first-child {
    margin-left: 16px;
  }
  > *:last-child {
    margin-right: 16px;
  }

  @media screen and (max-width: ${MOBILE_BREAKPOINT_MAX}px) {
    top: calc(134px + env(safe-area-inset-top));
  }

  --toolbar-size: 108px;

  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    left: var(--plans-tab-horizontal-padding);
    top: calc(
      var(--toolbar-size) + env(safe-area-inset-top) + var(--desktop-header-size) + var(--plans-header-padding-top)
    );
  }

  transition: opacity 0.3s, transform 0.3s, border-bottom 0.3s;
  &.collapsed {
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;
  }

  border-bottom: 2px solid var(--ion-color-light);
  &.borderless {
    border-bottom: 2px solid transparent;
  }
`

interface PlansHeaderChipsProps {
  className?: string
  collapsed?: boolean
  showFiltersChip: boolean
  nLinesFilter: number
  onNLinesFilterChange: (nLines: number) => void
  sortBy: string
  onSortByChange: (sortBy: SortingOption) => void
  editPreferences?: boolean
  onEditPreferencesClick?: () => void
  isOnTop?: boolean
}

export enum SortingOption {
  Featured = 'Featured',
  Recommended = 'Recommended',
  Carrier = 'Carrier',
  PriceLowToHigh = 'Price low',
  PriceHighToLow = 'Price high',

  // Is present in the new UI?
  NetworkPerformance = 'network-performance'
}

export const SortingOptions = [
  { label: 'Featured', value: 'Featured' },
  { label: 'Carrier', value: 'Carrier' },
  { label: 'Price low', value: 'Price low' },
  { label: 'Price high', value: 'Price high' }
]

export function PlansHeaderChips(props: PlansHeaderChipsProps) {
  const history = useHistory()
  const { nLinesFilter, sortBy, onNLinesFilterChange, onSortByChange } = props

  const filtersFlag = useFlag(FeatureFlag.FILTERS_UI)

  const filters = useFiltersStore()
  const diffCount = React.useMemo(() => {
    const diff = getFilterDiff(filters, getInitialPreferencesV3())
    return Object.keys(diff).length
  }, [filters])

  return (
    <>
      <div
        className={cx(
          chipsStyles,
          'plans-header-chips',
          props.collapsed ? 'collapsed' : '',
          props.isOnTop ? 'borderless' : '',
          props.className
        )}
      >
        {filtersFlag && props.showFiltersChip ? (
          <ControlChip
            className={HideDesktopStyles}
            title='Open plan filters'
            onClick={() => {
              const search = new URLSearchParams(history.location.search)
              search.set('filters-open', 'true')
              history.push({ ...history.location, pathname: window.location.pathname, search: search.toString() })
            }}
            icon='/assets/icons/ic_baseline-tune.svg'
            text={diffCount > 0 ? '+' + diffCount : ''}
          />
        ) : null}
        <DropdownSelector
          value={nLinesFilter}
          onValueChange={onNLinesFilterChange}
          icon={peopleOutline}
          options={[
            { label: '1 Line', value: 1 },
            { label: '2 Lines', value: 2 },
            { label: '3 Lines', value: 3 },
            { label: '4 Lines', value: 4 },
            { label: '5 Lines', value: 5 }
          ]}
        />

        <DropdownSelector
          value={sortBy}
          onValueChange={onSortByChange}
          icon={swapVerticalOutline}
          text={`Sort by:`}
          options={SortingOptions}
        />

        {props.editPreferences && (
          <ControlChip
            icon={EditIcon()}
            text={'Preference'}
            onClick={() => {
              applyGeneralStoreToEditStore()
              history.replace(
                RoutePaths.carrierSelector + '?open-from=' + MainNavigationPaths.plans + '/' + RECOMMENDED_TAB
              )
            }}
          />
        )}
      </div>
    </>
  )
}
