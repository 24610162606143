import { Geolocation } from '@capacitor/geolocation'
import { useQuery } from 'react-query'

export const locationKey = 'shouldShowRationale'
export function useLocationPermission() {
  const hasLocation = useQuery({
    queryKey: locationKey,
    queryFn: async () => {
      return { isGranted: (await Geolocation.checkPermissions()).location === 'granted' }
    }
  })

  async function requestLocation() {
    const pluginResult = await Geolocation.requestPermissions({ permissions: ['location'] })
    return { isGranted: pluginResult.location === 'granted', shouldShowRationale: pluginResult.location === 'prompt' }
  }

  return { hasLocation, requestLocation }
}

export function useRefetchPermissions() {
  const { hasLocation } = useLocationPermission()

  async function refetch() {
    hasLocation.refetch()
  }

  return refetch
}
