import { css } from '@emotion/css'

const drawerStyles = css`
  color: var(--ion-text-color);
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: start;

  padding: 24px 30px;

  .section {
    .section-title {
      font-size: 18px;
      font-weight: 900;
    }

    .chips-container {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .chip {
        width: fit-content;
        white-space: nowrap;
        padding: 5px 10px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.12);
        cursor: pointer;
      }
    }
  }
`

interface DrawerSectionsProps {
  sections?: { title: string; chips: string[] }[]
  onSearch: (search: string) => void
}

export function DrawerSections(props: DrawerSectionsProps) {
  return (
    <div className={drawerStyles}>
      {props.sections?.map(
        (section) =>
          section.chips.length > 0 && (
            <div className='section' key={section.title}>
              <span className='section-title'>{section.title}</span>
              <div className='chips-container'>
                {section.chips.map((search, index) => (
                  <div
                    key={`${search}-${index}`}
                    className='chip'
                    onClick={() => {
                      props.onSearch(search)
                    }}
                  >
                    <span>{search}</span>
                  </div>
                ))}
              </div>
            </div>
          )
      )}
    </div>
  )
}
