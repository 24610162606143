import { Route, useLocation } from 'react-router'
import { CSSTransition } from 'react-transition-group'
import ProfileMenuPage from '../../profile/ProfileMenuPage'
import { useIsMobileSize } from '../../../utils/hooks/useWindowSize'
import React from 'react'
import { css } from '@emotion/css'
import { MainNavigationPaths } from '../../../paths'
import HomePage from '../HomePage'

const SLIDE_DURATION = 0.5

const DetachStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const ProfileTransitionStyles = css`
  z-index: 999;
  &.profile-drawer-enter {
    ${DetachStyles}
    transform: translateX(100%);
  }
  &.profile-drawer-enter-active {
    transition: transform ${SLIDE_DURATION}s;
    transform: translateX(0%);
  }
  &.profile-drawer-exit {
    ${DetachStyles}
    transform: translateX(0%);
  }

  &.profile-drawer-exit-active {
    transition: transform ${SLIDE_DURATION}s;
    transform: translateX(100%);
  }
`

// Needed for profile animation
// IonRouterOutlet doesnt render unmatched components on the component tree.
// It acts as a switch the first time it is rendered, but caches old components
// after the second render
export function HomePageTransition() {
  const location = useLocation()
  const profileRef = React.useRef(null)
  const homepageRef = React.useRef(null)
  const isMobileSize = useIsMobileSize()

  const timeout = isMobileSize ? SLIDE_DURATION * 1000 : 0

  return (
    <>
      <Route path={MainNavigationPaths.home}>
        {({ match }) => {
          return (
            <CSSTransition
              unmountOnExit
              classNames={'home-transition'}
              nodeRef={homepageRef}
              in={location.pathname === match?.path}
              timeout={timeout}
            >
              <div ref={homepageRef}>
                <HomePage />
              </div>
            </CSSTransition>
          )
        }}
      </Route>
      <Route path={MainNavigationPaths.profile}>
        {({ match }) => {
          return (
            <CSSTransition
              unmountOnExit
              classNames={'profile-drawer'}
              nodeRef={profileRef}
              in={location.pathname === match?.path}
              timeout={timeout}
            >
              <div className={ProfileTransitionStyles} ref={profileRef}>
                <ProfileMenuPage />
              </div>
            </CSSTransition>
          )
        }}
      </Route>
    </>
  )
}
