import { css, cx } from '@emotion/css'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { HideMobileStyles } from '../../../utils/styles/ViewportUtils.styles'

const DesktopHeroStyles = css`
  padding: 40px var(--homepage-desktop-padding);
  display: flex;
  gap: 24px;
  justify-content: space-between;

  .home-title {
    font-size: max(min(40px, 3vw), 30px);
    font-weight: 900;
  }

  .barry-image-container {
    display: flex;
    justify-content: center;
    min-width: min(27vw, 500px);
  }

  .barry-image {
    margin-right: min(107px, 10%);
    max-width: min(234px, 74%);
  }

  .home-description {
    margin-top: 23px;
    font-weight: 500;
    font-size: max(min(22px, 2vw), 18px);
  }
`
export function DesktopHero() {
  return (
    <section className={cx(HideMobileStyles, DesktopHeroStyles)}>
      <div>
        <h1 className={cx(ResetMargin, 'home-title')}>
          Join the thousands of people who've found a 50% cheaper plan using Goji
        </h1>
        <p className={cx(ResetMargin, 'home-description')}>
          Compare and switch today to save hundreds of dollars per year
        </p>
      </div>
      <div className='barry-image-container'>
        <img
          className='barry-image'
          alt='goji barry sitting with a phone'
          src='/assets/images/barry/barry_sitting2.svg'
        />
      </div>
    </section>
  )
}
