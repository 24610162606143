import { cx } from '@emotion/css'
import { IonHeader, IonPage, IonRouterLink, IonToolbar } from '@ionic/react'
import { Auth } from 'aws-amplify'
import React from 'react'
import { Redirect, useHistory } from 'react-router'
import Content from '../../components/Content'
import { resetAllSlices } from '../../store'
import useAuthStore from '../../store/auth'
import { DesktopHeader } from '../home/DesktopHeader/DesktopHeader'
import { trackAmplitudeEvent } from '../../utils/amplitude'
import { MainNavigationPaths, ProfilePaths } from '../../paths'
import { DesktopFooter } from '../home/DesktopFooter/DesktopFooter'
import { ProfileHeaderStyles, ProfileHeadingStyles, ProfileMenuPageStyles } from './ProfileMenuPageStyles'
import { useIsMobileSize } from '../../utils/hooks/useWindowSize'
import { useFlag } from '../../utils/feature-flags/useFlag'
import { FeatureFlag } from '../../utils/FeatureFlags'
import CloseButton from '../../components/CloseButton/CloseButton'
import { HideDesktopStyles } from '../../utils/styles/ViewportUtils.styles'
import { ResetMargin } from '../../utils/styles/MarginUtils.styles'

type MenuItem = {
  text: string
  icon: string
  link: string
}
const menuItems = [
  {
    text: 'Plan preferences',
    icon: '/assets/icons/note.svg',
    link: ProfilePaths.planPreferences
  },
  {
    text: 'Saved plans',
    icon: '/assets/icons/saved_plans.svg',
    link: ProfilePaths.savedPlans
  },
  {
    text: 'Address Book',
    icon: '/assets/icons/address_book.svg',
    link: ProfilePaths.addressBook
  },
  {
    text: 'About',
    icon: '/assets/icons/info.svg',
    link: ProfilePaths.about
  }
]

function MenuProfileItem({
  item,
  onClick,
  showArrow
}: {
  item: MenuItem
  showArrow: boolean
  onClick?: (evt: React.MouseEvent) => void
}) {
  return (
    <IonRouterLink onClick={onClick} routerLink={item.link}>
      <li className='menu-profile-item'>
        <div className='menu-profile-item-title'>
          <img src={item.icon} alt={item.text} />
          <span>{item.text}</span>
        </div>
        <div className='menu-profile-item-arrow'>
          {showArrow ? <img className='arrow-next' src='/assets/icons/arrow_next.svg' alt='Arrow next icon' /> : null}
        </div>
      </li>
    </IonRouterLink>
  )
}

const ProfileMenuPage = () => {
  const currentUser = useAuthStore((state) => state.currentUser)
  const history = useHistory()
  const [profileState, setProfileState] = React.useState<'stale' | 'signing-out'>('stale')
  const isMobile = useIsMobileSize()

  const isChatUiEnabled = useFlag(FeatureFlag.CHAT_UI)

  const onSignout = () => {
    setProfileState('signing-out')
    Auth.signOut()
      .then(() => {
        trackAmplitudeEvent('User Log Out')
        setProfileState('stale')
        resetAllSlices()
        history.replace('/')
      })
      .catch(() => {
        setProfileState('stale')
      })
  }

  const onClickSignout = (evt: React.MouseEvent) => {
    evt.preventDefault()
    onSignout()
  }

  if (!currentUser) {
    return <Redirect to={MainNavigationPaths.signIn} />
  }

  return (
    <IonPage>
      <IonHeader className={ProfileHeaderStyles}>
        <IonToolbar>
          <div className={cx(HideDesktopStyles, ProfileHeadingStyles, 'close-div')}>
            <CloseButton className='close-button' routerLink={MainNavigationPaths.home} />
          </div>
        </IonToolbar>
      </IonHeader>
      {isMobile ? null : <DesktopHeader />}
      <Content className={cx(ProfileMenuPageStyles)}>
        <div className='content-heading'>
          <section className='welcome-profile-wrapper'>
            <div className='welcome-info-wrapper'>
              <h1>Good to see you,</h1>
              <span className='user-email'>{currentUser?.email}</span>
            </div>
            <div className='image-wrapper'>
              <img src='/assets/images/barry/barry_walking_elipse.svg' alt='Barry walking' />
            </div>
          </section>
        </div>
        <div className='section-separator'></div>
        <section className='menu-profile-wrapper'>
          <ul className={cx(ResetMargin, 'menu-profile-list')}>
            {menuItems.map((item) => {
              if (isChatUiEnabled && item.link === ProfilePaths.planPreferences) {
                return null
              }
              return <MenuProfileItem key={item.text} item={item} showArrow />
            })}

            <MenuProfileItem
              onClick={onClickSignout}
              item={{
                text: profileState === 'signing-out' ? 'Signing out...' : 'Sign out',
                icon: '/assets/icons/profile/logout.svg',
                link: '#'
              }}
              showArrow={false}
            />
          </ul>
        </section>
      </Content>
      {isMobile ? null : <DesktopFooter className='homepage-footer' />}
    </IonPage>
  )
}

export default ProfileMenuPage
