import { css } from '@emotion/css'
import { MOBILE_BREAKPOINT } from '../../utils/hooks/useWindowSize'

export const PlansPageHeaderStyles = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    padding: 0 var(--plans-tab-horizontal-padding);
  }
`

export const PlansPageFooterWrapper = css`
  z-index: 6;
`

export const CompareButtonsStyles = css`
  @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    transform: translateX(calc(-1 * var(--plans-tab-horizontal-padding)));
  }

  transition: opacity 0.3s;
  &.hide {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0s;
    transition-delay: 0s, 0.3s;
  }
`

export const PlansPageDesktopHeaderStyles = css`
  border-bottom: 1px solid var(--ion-color-light-shade);
`

export const PlansPageStyles = css`
  --overflow: hidden;
  text-align: center;
  position: relative;
  padding: calc(env(safe-area-inset-top)) 50px;
  .custom-width-limiter {
    height: 100%;
  }

  .barry {
    align: center;
    width: 35vw;
    max-width: 200px;
    margin-top: 80px;
  }
`

export const SwiperStyles = css`
  height: 100% !important;
  .swiper-wrapper {
    .swiper-slide {
      &.is-barry {
        overflow-y: auto;
        touch-action: pan-y;
      }
      @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
        height: calc(100vh - 57px);
      }
      @media screen and (min-width: ${MOBILE_BREAKPOINT}px) {
        // 76px is the height of the header
        height: calc(100vh - 76px);
      }
    }
  }
`
