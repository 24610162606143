import { css, cx } from '@emotion/css'
import { CheckboxChangeEventDetail, CheckboxCustomEvent, IonCheckbox } from '@ionic/react'
import PlanResultCard, { PlanResultCardProps } from '../PlanResult/PlanResultCard'
import { Plan } from '../../services/planService/Plan'

interface ComparePlanResultCardProps extends Omit<PlanResultCardProps, 'afterLogo'> {
  isComparing: boolean
  onCheckboxSelected?: (planResult: Plan, newIsSelectedState: boolean) => void
  disabled: boolean
  isFuzzy?: boolean
  afterCompare?: React.ReactNode
  compareView?: boolean
}

const ComparePlanResultCardStyles = css`
  display: inline-flex;
  align-items: center;

  .compare-checkbox {
    flex-shrink: 1;
    margin-left: 12px;
    width: 24px;
    height: 24px;
  }
`

const PlanResultCardWrapperStyles = css`
  flex-grow: 1;
  display: flex;
  justify-content: center;

  .plan-result-card {
    width: 100%;
  }
`

export const ComparePlanResultCard = (props: ComparePlanResultCardProps) => {
  const { isComparing, onCheckboxSelected, disabled, isFuzzy, compareView, ...planResultCardProps } = props

  const onCheckboxChange = (evt: CheckboxCustomEvent<CheckboxChangeEventDetail<Plan>>) => {
    evt.stopPropagation()
    onCheckboxSelected?.(props.plan, evt.detail.checked)
  }

  const onPlanClick = () => {
    if (disabled) return
    if (compareView) {
      onCheckboxSelected?.(props.plan, !isComparing)
    } else {
      planResultCardProps.onPlanClick?.(props.plan)
    }
  }

  return (
    <div className={cx(props.className, ComparePlanResultCardStyles)}>
      <div className={PlanResultCardWrapperStyles}>
        <PlanResultCard className='plan-result-card' {...planResultCardProps} onPlanClick={onPlanClick} />
      </div>

      {compareView ? (
        <IonCheckbox
          className='compare-checkbox'
          checked={isComparing}
          onIonChange={onCheckboxChange}
          disabled={!isComparing && disabled}
        />
      ) : null}
    </div>
  )
}
