import { css, cx } from '@emotion/css'
import { IonRouterLink } from '@ionic/react'
import { RoutePaths } from '../../../paths'
import { stopPropagation } from '../../../utils/events/preventDefault'
import { ResetMargin } from '../../../utils/styles/MarginUtils.styles'
import { HideMobileStyles } from '../../../utils/styles/ViewportUtils.styles'
import { IgnoreHomePadding } from '../map-section/HomeMapBase'

const FONT_SIZE_BREAKPOINT = 980

const PlanCalloutStyles = css`
  color: var(--ion-text-color);

  .html-content {
    position: absolute;
    padding: 0 120px;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    height: 100%;
    width: 80%;
  }

  .title {
    font-size: 38px;
    margin-bottom: 24px;
  }

  .barry-talking {
    height: 254px;
    max-width: 218px;
    transform: rotateY(180deg);
  }

  @media screen and (max-width: ${FONT_SIZE_BREAKPOINT}px) {
    .title {
      font-size: 27px;
    }
    .barry-talking {
      height: 204px;
    }
  }

  .background-svg {
    border-radius: 28px;
    width: 100%;
  }

  .html-content,
  .background-svg {
    max-height: 335px;
    min-height: 206px;
  }
`

const FindMyPlanLinkStyles = css`
  font-size: 24px;
  @media screen and (max-width: ${FONT_SIZE_BREAKPOINT}px) {
    font-size: 18px;
  }
  color: var(--ion-text-color);
  text-decoration: underline;
`

export function PlanCalloutDesktop(props: { onClick?: () => void }) {
  return (
    <IonRouterLink onClickCapture={props.onClick} className={HideMobileStyles} routerLink={RoutePaths.chatFinder}>
      <section className={cx(IgnoreHomePadding, PlanCalloutStyles)}>
        <div className='html-content'>
          <div className='titles-wrapper'>
            <h2 className={cx(ResetMargin, 'title')}>Let’s find you a plan 50% cheaper with better coverage</h2>
            <IonRouterLink
              onClick={stopPropagation}
              className={FindMyPlanLinkStyles}
              routerLink={RoutePaths.chatFinder}
            >
              Find my plan
            </IonRouterLink>
          </div>
          <img
            className='barry-talking'
            src='/assets/images/barry/barry_talking.svg'
            alt='goji barry talking on the phone'
          />
        </div>
        <svg
          className='background-svg'
          xmlns='http://www.w3.org/2000/svg'
          width='1162'
          height='335'
          viewBox='0 0 1162 335'
          fill='none'
          preserveAspectRatio='none'
        >
          <rect width='1162' height='335' rx='24' fill='#ECF3FE' />
          <path
            d='M1177.2 530.543L1177.2 164.749L590 164.748C590 366.771 753.772 530.543 955.795 530.543L1177.2 530.543Z'
            fill='#8CBAFF'
          />
          <path
            d='M1177.2 530.543C1177.2 328.52 1013.42 164.748 811.402 164.748L590 164.748C590 366.771 753.772 530.543 955.795 530.543L1177.2 530.543Z'
            fill='white'
            fillOpacity='0.25'
          />
          <path
            d='M230 -177.262L-115.584 -177.262L-115.584 101.631L-48.8925 101.631C105.136 101.631 230 -23.2337 230 -177.262Z'
            fill='#FF7E20'
          />
          <path
            d='M230 -177.262L163.308 -177.262C9.2804 -177.262 -115.584 -52.3973 -115.584 101.631L-48.8925 101.631C105.136 101.631 230 -23.2337 230 -177.262Z'
            fill='#8CBAFF'
          />
        </svg>
      </section>
    </IonRouterLink>
  )
}
