import * as React from 'react'
import { css, cx } from '@emotion/css'
import { IonButton, IonIcon, IonPopover, IonSpinner, IonText } from '@ionic/react'
import { chevronDown } from 'ionicons/icons'
import { NetworkType, NetworkTypeValue } from '../../../services/NetworkType.service'
import LegendModal from '../LegendModal'
import { UseCarrier } from '../../../services/Carrier.service'
import { Carrier } from '../../../store/carrierSelector'
import { AddressesSearchbar } from '../../../pages/network-quality/AddressSearchbar'
import { useUnsavedAddresses } from '../hooks/useUnsavedAddresses'
import { Address } from '../../../store/address'
import { MOBILE_BREAKPOINT, useIsMobileSize } from '../../../utils/hooks/useWindowSize'
import { SplitCarrierList } from './SplitCarriersList'
import { AddressBookButton } from '../address-book-button/AddressBookButton'

const TopBarButton = css`
  height: 40px;
  &::part(native) {
    background-color: var(--ion-background-color);
    padding: 8px 16px;
  }
`

const CarrierPopoverStyles = css`
  --offset-y: 2px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    --offset-x: 12px;
  }
`

const CarrierDropdownStyles = css`
  height: 40px;
  .current-carrier {
    font-size: 16px;
  }
`

const CarrierDropdown = (props: {
  selectedCarrier?: Carrier
  addresses: Address[]
  onChangeCarrier: (carrier: Carrier) => void
  selectedTechnology: NetworkTypeValue
}) => {
  const carriers = UseCarrier.useCarriersSplit(UseCarrier.useAvailableInMyRegion(UseCarrier.useAllCarriers()))
  const defaultCarriers = carriers.data ? carriers.data.defaultCarriers : []
  const otherCarriers = carriers.data
    ? carriers.data.otherCarriers.filter((carrier) => !!carrier.is_visible_on_map)
    : []
  const allCarriers = defaultCarriers.concat(otherCarriers)

  const onClickCarrier = (carrier: Carrier) => {
    props.onChangeCarrier(carrier)
  }

  return (
    <>
      <IonButton
        className={cx(CarrierDropdownStyles, ShadowedStyles, TopBarButton)}
        size='small'
        shape='round'
        color='light'
        id='carrier-dropdown'
      >
        {props.selectedCarrier?.id ? (
          <IonText className='current-carrier' color='primary'>
            {props.selectedCarrier?.name}
          </IonText>
        ) : (
          <IonSpinner name='dots' color='primary' />
        )}{' '}
        <IonIcon className='chevron' icon={chevronDown} color='dark' />
      </IonButton>
      <IonPopover
        arrow={false}
        trigger='carrier-dropdown'
        alignment='start'
        dismissOnSelect
        className={CarrierPopoverStyles}
      >
        <SplitCarrierList
          carriers={allCarriers}
          onClick={onClickCarrier}
          selectedTechnology={props.selectedTechnology}
        />
      </IonPopover>
    </>
  )
}

const LegendButtonStyles = css`
  &::part(native) {
    padding: 0 8px;
  }
`
const LegendButton: React.FC<{ onClick: () => void }> = (props) => {
  return (
    <IonButton
      className={cx(TopBarButton, ShadowedStyles, LegendButtonStyles)}
      color='light'
      title='Legend'
      size='small'
      shape='round'
      onClick={props.onClick}
    >
      <svg width='42' height='12' viewBox='0 0 42 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect y='5.65686' width='8' height='8' transform='rotate(-45 0 5.65686)' fill='#E6000C' />
        <rect x='15.3137' y='5.65686' width='8' height='8' transform='rotate(-45 15.3137 5.65686)' fill='#FF781F' />
        <rect x='30.6274' y='5.65686' width='8' height='8' transform='rotate(-45 30.6274 5.65686)' fill='#43A047' />
      </svg>
    </IonButton>
  )
}

export const ShadowedStyles = css`
  &.button::part(native) {
    box-shadow: 0px 1px 2px var(--ion-color-light-shade);
  }

  &:not(.button) {
    box-shadow: 0px 1px 2px var(--ion-color-light-shade);
  }
`

const InlineSelectorStyles = css`
  display: flex;
  align-items: center;
  background-color: var(--ion-background-color);
  border-radius: 60px;
  margin: 4px 3px;
  padding: 4px;

  .selector-item {
    height: 100%;
  }
`
const InlineSelector: React.FC<{ items: React.ReactNode[] }> = (props) => {
  return (
    <div className={cx(ShadowedStyles, InlineSelectorStyles)}>
      {props.items.map((item, i) => (
        <div key={i} className='selector-item'>
          {item}
        </div>
      ))}
    </div>
  )
}

const TopBarStyles = css`
  position: absolute;

  top: calc(env(safe-area-inset-top) + 16px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;

  padding: 16px;
  padding-top: 0;

  .chevron {
    font-size: 18px;
    transform: translateX(5px);
  }

  .technology-button {
    margin: 0;
    height: 100%;
    &::part(native) {
      padding: 0 12px;
      background-color: transparent;
      color: var(--ion-color-medium);
      font-weight: 600;
      font-size: 16px;
    }

    &.active::part(native) {
      background-color: var(--ion-color-primary-tint);
      color: var(--ion-color-primary);
    }
  }

  .address-searchbar {
    min-width: 100%;
  }

  &.desktop {
    width: 100%;
    max-width: 760px;
    top: env(safe-area-inset-top);
    padding-top: 16px;

    .address-searchbar {
      min-width: 290px;
      flex-grow: 1;
    }

    & > * {
      height: 35px;
      margin: 0;
    }

    .edit-locations {
      font-size: 16px;
      &::part(native) {
        box-shadow: 0px 1px 2px var(--ion-color-light-shade);
        background-color: var(--ion-background-color);
        color: var(--ion-color-primary);
      }
      &.active::part(native) {
        background-color: var(--ion-color-primary-tint);
        color: var(--ion-color-primary);
      }
    }
    .center-icon {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .edit-icon {
      color: var(--ion-color-medium);
    }
  }
`

interface TopBarProps {
  reducedUI?: boolean
  onSwitchNetworkType: (networkType: NetworkTypeValue) => void
  onChangeCarrier: (carrier: Carrier) => void
  selectedCarrier?: Carrier
  selectedTechnology: NetworkTypeValue
  addresses: Address[]
}
const TopBar: React.FC<TopBarProps> = (props) => {
  const isMobile = useIsMobileSize()
  const [isLegendModalOpen, setLegendModalOpen] = React.useState(false)
  const { setLocalAddress: setAddressInBuffer } = useUnsavedAddresses()
  const { addresses } = props

  return (
    <>
      <section className={cx(TopBarStyles, isMobile ? 'mobile' : 'desktop')}>
        {props.reducedUI ? null : (
          <AddressesSearchbar
            className='address-searchbar'
            addresses={addresses}
            onAddressSelected={setAddressInBuffer}
            buttonsSlot={<AddressBookButton />}
            searchBarPlaceholder='Search location'
            onMap
          />
        )}
        <CarrierDropdown
          addresses={props.addresses}
          selectedCarrier={props.selectedCarrier}
          onChangeCarrier={props.onChangeCarrier}
          selectedTechnology={props.selectedTechnology}
        />
        <InlineSelector
          items={[
            <IonButton
              onClick={() => props.onSwitchNetworkType(NetworkType['5G'])}
              className={cx('technology-button', props.selectedTechnology === NetworkType['5G'] ? 'active' : '')}
              color='light'
              size='small'
              shape='round'
            >
              5G
            </IonButton>,
            <IonButton
              onClick={() => props.onSwitchNetworkType(NetworkType['4G'])}
              className={cx('technology-button', props.selectedTechnology === NetworkType['4G'] ? 'active' : '')}
              color='light'
              size='small'
              shape='round'
            >
              4G
            </IonButton>
          ]}
        />
        {props.reducedUI ? null : (
          <>
            <LegendButton onClick={() => setLegendModalOpen(true)} />
            <LegendModal isOpen={isLegendModalOpen} onClose={() => setLegendModalOpen(false)} />
          </>
        )}
      </section>
    </>
  )
}

export default TopBar
