import { css, cx } from '@emotion/css'
import { IonRouterLink } from '@ionic/react'
import { MainNavigationPaths, RoutePaths } from '../../../paths'
import { MOBILE_BREAKPOINT } from '../../../utils/hooks/useWindowSize'

const AddressBookButtonStyles = css`
  position: relative;
  width: 24px;
  height: 24px;

  a {
    height: 24px;
  }

  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    width: 20px;
    height: 20px;
  }
`

export function AddressBookButton() {
  return (
    <IonRouterLink
      className={cx(AddressBookButtonStyles, 'edit-locations')}
      routerLink={RoutePaths.addressBook + '?back-to=' + MainNavigationPaths.map}
    >
      <img className='edit-icon' alt='address book' src={'/assets/icons/address-book.svg'} />
    </IonRouterLink>
  )
}
