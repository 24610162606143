import { css } from '@emotion/css'

export const FullHeight = css`
  height: 100%;
`
export const FlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`
