import { cx } from '@emotion/css'
import React from 'react'
import { NetworkTypeValue } from '../../../services/NetworkType.service'
import { useGeneralStore } from '../../../store'
import { Address } from '../../../store/address'
import { Carrier } from '../../../store/carrierSelector'
import MapComponent from '../../Map/Map'
import { useMarkers } from '../../Marker'
import { DeckManager } from '../DeckManager'
import { FCCDataSource, GeodataService } from '../Geodata.service'

const US_CENTER = {
  latitude: 40.116386,
  longitude: -101.299591
}

function getInitialCenter(
  selectedAddressFromStore: Address | undefined,
  addresses: Address[]
): { latitude: number; longitude: number } {
  if (selectedAddressFromStore && addresses.find((address) => address.placeId === selectedAddressFromStore.placeId)) {
    return selectedAddressFromStore
  }

  if (addresses.length > 0) {
    return addresses[0]
  }

  return US_CENTER
}

const InnerCoverageMap = ({
  className,
  markerIcon,
  markerIconActive,
  googleMap,
  ...props
}: {
  className?: string
  deckManagerRef: React.MutableRefObject<DeckManager | undefined>
  googleMap: google.maps.Map | undefined
  setGoogleMap: React.Dispatch<React.SetStateAction<google.maps.Map | undefined>>
  networkType: NetworkTypeValue
  selectedCarrier: Carrier | undefined
  onClickMarker: (address: Address) => void
  addresses: Address[]
  zoom: number
  minZoom?: number
  gestureHandling?: string
  markerIcon?: string
  markerIconActive?: string
}) => {
  const [selectedAddress, map] = useGeneralStore((state) => [state.selectedAddress, state.map])
  const initialCenter = React.useMemo(() => {
    const initialCenterAddr = getInitialCenter(selectedAddress, props.addresses)
    const latlng: google.maps.LatLngLiteral = {
      lat: initialCenterAddr.latitude,
      lng: initialCenterAddr.longitude
    }
    return latlng
  }, [props.addresses, selectedAddress])
  const { deckManagerRef, setGoogleMap, networkType, selectedCarrier } = props

  const customizeMap = React.useCallback(
    (map: google.maps.Map) => {
      deckManagerRef.current = new DeckManager({
        map,
        carrierId: selectedCarrier?.id ?? '',
        geodataService: new GeodataService(new FCCDataSource())
      })
      deckManagerRef.current.setNetworkType(networkType)
      deckManagerRef.current.setup()

      setGoogleMap(map)
    },
    [deckManagerRef, networkType, selectedCarrier?.id, setGoogleMap]
  )

  const markerLocations = React.useMemo(() => {
    return props.addresses.map((address) => ({ address, active: selectedAddress?.placeId === address.placeId }))
  }, [props.addresses, selectedAddress?.placeId])

  useMarkers({
    onClick: props.onClickMarker,
    locations: markerLocations,
    map: googleMap,
    icon: markerIcon,
    iconActive: markerIconActive
  })

  return (
    <MapComponent
      className={cx('coverage-map-map', className)}
      customizeMap={customizeMap}
      center={initialCenter}
      zoom={props.zoom}
      maxZoom={map.maxZoom}
      minZoom={props.minZoom ?? undefined}
      gestureHandling={props.gestureHandling}
    ></MapComponent>
  )
}

export default React.memo(InnerCoverageMap)
