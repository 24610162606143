import { css } from '@emotion/css'

export const TitlesWrapperStyles = css`
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox
`
export const SavedPlansHeaderStyles = css`
  .swiper-wrapper {
    justify-content: center;
    transform: translateX(0px) !important;
    span {
      font-size: 18px;
      font-weight: 900;
    }

    .swiper-slide-active {
      margin: 0 !important;
      > div {
        transform: unset !important;
        transition: unset !important;
        opacity: 1 !important;
      }
    }
  }
  .titles-wrapper {
    align-items: center;
    justify-content: center !important;
    > div {
      opacity: 1 !important;
      font-size: 18px;
      font-weight: 900 !important;
    }
  }

  .back-arrow {
    position: absolute;
    left: 30px;
    top: 15px;
    color: var(--goji-primary-black);
    height: var(--header-full-size);

    z-index: 2;
    transition: transform 0.3s, opacity 0.3s height 0.3s;
  }
  .back-arrow.state-collapsed {
    height: var(--plans-header-collapsed-height);
    transform: translateY(-100%);
    opacity: 0;
  }
`

export const SavedPlansStyles = css`
  --overflow: hidden;
  .no_saved_plans {
    width: 60%;
    margin: 140px auto 24px;
  }

  @media (max-width: 768px) {
    .no_saved_plans {
      width: 85%;
    }
  }
`

export const NoSavedPlansBannerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .barry_image_wrapper {
    margin-top: 40px;
    max-width: 40%;
  }

  .info_no_saved_plans_wrapper {
    text-align: center;
    h1 {
      font-size: 22px;
      font-weight: 900;
      margin: 10px 0;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: var(--ion-color-medium);
      margin: 10px 0;
    }
  }
`

export const NoSavedPlansStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  .buttons_no_saved_plans_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  .recommended_plans_btn {
    text-transform: uppercase;
    font-size: 16px;
    height: 52px;
    font-weight: 700;
  }

  .all_plans_btn {
    font-size: 16px;
    height: 52px;
  }
`

export const SavedPlansFooterWrapper = css`
  --bottom-fixed: calc(env(safe-area-inset-bottom) + 15px);
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;

  ion-footer {
    bottom: 0;
    right: 0;
    position: fixed;
  }
`
